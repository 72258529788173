<ejs-sidebar id="sidebar-menu" #sidebarInstance type='Over' [width]='width' [position]='position'
             style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true>

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
       (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>

  <div class='p-15'>
    <h2 *ngIf="!deliverableInput">Add Deliverable</h2>
    <h2 *ngIf="deliverableInput">Update {{deliverableItemName}} for {{companyName}}</h2>

    <ul class="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x mb-15" role="tablist">
      <li class="nav-item mr-0" [class.menu-item-active]="selectedTab === 'deliverable'"
          (click)="setTab('deliverable')">
        <a class="nav-link mx-2" data-toggle="tab" [class.active]="selectedTab === 'deliverable'">
          <span class="nav-text">Deliverable</span>

        </a>
      </li>

      <li class="nav-item mr-0" [class.menu-item-active]="selectedTab === 'dates'" (click)="setTab('dates')">
        <a class="nav-link mx-2" data-toggle="tab" [class.active]="selectedTab === 'dates'">
          <span class="nav-text">Dates</span>
        </a>
      </li>
    </ul>

    <form id="formId" [formGroup]="deliverableForm" #formDir="ngForm" class="form-horizontal" novalidate=""
          style="margin-top: 26px;" [ngClass]="selectedTab === 'deliverable' ? 'show' : 'hide'">

      <div class="d-flex mt-2">

        <div class="form-group sf-outline-input mb-10 w-100 mr-2">
          <ejs-dropdowntree id='deliverable_item_codee' formControlName="deliverable_item_code" floatLabelType="Auto"
                            [fields]='deliverableItemFields' cssClass="e-filled" [showClearButton]=false
                            placeholder='Deliverable Item (Service) *'
                            [allowFiltering]='true'
                            (select)="selectDeliverableItem($event, deliverableForm);makeNotesMandatory()"
                            (blur)="deliverableForm.controls.deliverable_item_code.markAsTouched()">
          </ejs-dropdowntree>
          <div *ngIf="deliverableForm.controls.deliverable_item_code.invalid">
            <div
              *ngIf="deliverableForm.controls.deliverable_item_code.errors.required && deliverableForm.controls.deliverable_item_code.touched "
              class="e-error">
              Please select a service
            </div>
          </div>
        </div>

        <div class="form-group sf-outline-input mb-10 w-100 ml-2">
          <ejs-dropdownlist #orderLineItemDropdown id='order_line_item_code' formControlName="order_line_item_code"
                            [dataSource]='lineItems' floatLabelType="Auto" [fields]='orderLineItemFields'
                            cssClass="e-filled" (change)="makeNotesMandatory()"
                            showClearButton="true" placeholder='Order Line Item' [allowFiltering]='true'
                            (blur)="deliverableForm.controls.order_line_item_code.markAsTouched()">
          </ejs-dropdownlist>
        </div>

        <div class="form-group sf-outline-input mb-10 w-100 ml-2" *ngIf="showArticleList">

          <ejs-dropdownlist #articleDropdown id='article_code' [dataSource]='articleList' class="filter-topbar ml-3"
                            formControlName="article_code" placeholder='Articles' [fields]='articleFields'
                            floatLabelType="Auto" allowFiltering="true" (filtering)="onFiltering($event)"
                            cssClass="e-filled" filterBarPlaceholder='Search' [showClearButton]=true
                            (change)="selectArticle(deliverableForm);makeNotesMandatory()" (select)="onSelect($event)">
          </ejs-dropdownlist>
          <a *ngIf="deliverableForm.controls.article_code.value" [href]="openArticleLink()" target="_blank" rel="noopener noreferrer" id="link">View article on LTD</a>
        </div>
      </div>


      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="description" [multiline]="true" placeholder="Description" cssClass="e-filled"
                     formControlName="description" floatLabelType="Auto" (change)="makeNotesMandatory()"
                     (blur)="deliverableForm.controls.description.markAsTouched()"></ejs-textbox>
        <div *ngIf="deliverableForm.controls.description.errors">
          <div
            *ngIf="deliverableForm.controls.description.errors.maxlength && deliverableForm.controls.description.touched"
            class="e-error">
            Description cannot be more than 1000 characters
          </div>
        </div>
      </div>


      <div class="d-flex">
        <div class="form-group sf-outline-input mb-10 w-100 mr-2">
          <ejs-dropdowntree id='deliverable_status_code' formControlName="deliverable_status_code" floatLabelType="Auto"
                            [fields]='deliverableStatusFields' cssClass="e-filled" value="'open'"
                            placeholder='Deliverable Status *'
                            [allowFiltering]='true'
                            (change)="onSelectStatus($event, deliverableForm, deliverableNoteForm); makeNotesMandatory()"
                            (blur)="deliverableForm.controls.deliverable_status_code.markAsTouched()">
          </ejs-dropdowntree>
          <div *ngIf="deliverableForm.controls.deliverable_status_code.invalid">
            <div
              *ngIf="deliverableForm.controls.deliverable_status_code.errors.required && deliverableForm.controls.deliverable_status_code.touched "
              class="e-error">
              Please select a status
            </div>
          </div>
        </div>

        <div class="form-group sf-outline-input mb-10 w-100 ml-2 mr-2">
          <ejs-dropdownlist id='cost_center_code' formControlName="cost_center_code" [dataSource]='costCenter'
                            floatLabelType="Auto" [fields]='costCenterFields' cssClass="e-filled"
                            placeholder='Cost Center *' (change)="makeNotesMandatory()"
                            [allowFiltering]='true' (blur)="deliverableForm.controls.cost_center_code.markAsTouched()">
          </ejs-dropdownlist>
          <div *ngIf="deliverableForm.controls.cost_center_code.invalid">
            <div
              *ngIf="deliverableForm.controls.cost_center_code.errors.required && deliverableForm.controls.cost_center_code.touched "
              class="e-error">
              Please select a cost center
            </div>
          </div>
        </div>

        <div class="form-group sf-outline-input mb-10 w-100 mr-2">
          <ejs-dropdownlist id='deliverable_owner_code' formControlName="deliverable_owner_code" [dataSource]='users'
                            floatLabelType="Auto" [fields]='userFields' cssClass="e-filled"
                            placeholder='Deliverable Owner *' [allowFiltering]='true' (change)="makeNotesMandatory()"
                            (blur)="deliverableForm.controls.deliverable_owner_code.markAsTouched()" (open)="Complete($event)">
                            <ng-template  #itemTemplate="" let-data style="display: inline-block">
                              <div style="display: inline-block" *ngIf="data.is_disable_yn !=1">
                                <span>{{data.first_name}}</span>
                              </div>
                              <div style="display: inline-block" *ngIf="data.is_disable_yn ==1" >
                                <span [ngStyle]="{'color':'#d3d3d3'}">{{data.first_name}}</span>
                              </div>

                            </ng-template>
          </ejs-dropdownlist>
          <div *ngIf="deliverableForm.controls.deliverable_owner_code.errors">
            <div
              *ngIf="deliverableForm.controls.deliverable_owner_code.errors.required && deliverableForm.controls.deliverable_owner_code.touched "
              class="e-error">
              Please select a deliverable owner
            </div>
          </div>
        </div>
      </div>


      <div class="d-flex">


        <div class="form-group sf-outline-input mb-10 w-100 mr-2 ml-2">
          <ejs-datepicker formControlName="start_date" openOnFocus="true" [allowEdit]=false format="dd-MMM-yyyy"
                          (blur)="deliverableForm.controls.start_date.markAsTouched()" placeholder='Start Date'
                          floatLabelType='Auto' cssClass="e-filled">
          </ejs-datepicker>
          <div *ngIf="deliverableForm.controls.start_date.errors">
            <div
              *ngIf="deliverableForm.controls.start_date.errors.required && deliverableForm.controls.start_date.touched "
              class="e-error">
              Start Date is required
            </div>
          </div>
        </div>

        <div class="form-group sf-outline-input mb-10 w-100 ml-2">
          <ejs-datepicker formControlName="end_date" openOnFocus="true" [allowEdit]=false format="dd-MMM-yyyy"
                          [min]="deliverableForm.value.start_date"
                          (blur)="deliverableForm.controls.end_date.markAsTouched()"
                          placeholder='End Date' floatLabelType='Auto' cssClass="e-filled">
          </ejs-datepicker>
          <div *ngIf="deliverableForm.errors">
            <div *ngIf="deliverableForm.errors.fromToDate" class="e-error">
              End date cannot be less than start date
            </div>
          </div>
        </div>

        <div class="form-group sf-outline-input mb-10 w-100 ml-2">
          <ejs-datepicker formControlName="expiry_date" openOnFocus="true" [allowEdit]=false format="dd-MMM-yyyy"
                          [min]="deliverableForm.value.start_date"
                          (blur)="deliverableForm.controls.expiry_date.markAsTouched()"
                          placeholder='Expiry Date' floatLabelType='Auto' cssClass="e-filled">
          </ejs-datepicker>
          <div *ngIf="deliverableForm.errors">
            <div *ngIf="deliverableForm.errors.expiry_date" class="e-error">
              Expiry date cannot be less than start date
            </div>
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-checkbox formControlName="is_external_provider_yn" #wcheckbox label="External Provider: "
                      labelPosition="Before" [disabled]="disabledState"></ejs-checkbox>
      </div>

    </form>

    <div class="float-right" [ngClass]="selectedTab === 'dates' ? 'show' : 'hide'">

      <ejs-checkbox [(ngModel)]="showRelative" #wcheckbox label="Show Relative Dates" labelPosition="Before">
      </ejs-checkbox>
    </div>

    <form [ngClass]="selectedTab === 'dates' ? 'show' : 'hide'" [formGroup]="deliverableItemDateForm" #formDir="ngForm"
          class="form-horizontal" style="padding-top: 30px;" novalidate="">


      <div class="roww mxx-2">
        <!--        <div class="px-0 col-4">-->

        <div *ngFor="let deliverableDates of deliverableItemDates">
          <div class="d-flex" *ngIf="findDateFromArray(deliverableDates.deliverable_item_date_code)">
            <div class="form-group sf-outline-input mb-10 align-self-center pr-4"
                 [ngClass]="showRelative ? 'show' : 'hide'">
              <ejs-radiobutton
                [formControlName]="'date_selection_' + strReplace(deliverableDates.deliverable_item_date_code)" label=""
                [name]="strReplace(deliverableDates.deliverable_item_date_code)"
                (checkedChange)="disableFields($event, 'dateSelection', deliverableDates.deliverable_item_date_code, deliverableItemDateForm)"
                cssClass="e-filled e-small"></ejs-radiobutton>
            </div>

            <div class="form-group sf-outline-input mx-3 mr-4" [ngClass]="showRelative ? 'w-25' : 'w-50'">
              <!-- <ejs-datepicker [formControlName]="strReplace(deliverableDates.deliverable_item_date_code)"
                              openOnFocus="true" [allowEdit]=false
                              (change)="onDateChange($event, strReplace(deliverableDates.deliverable_item_date_code), deliverableNoteForm, deliverableItemDateForm)"
                              (blur)="deliverableItemDateForm.controls[strReplace(deliverableDates.deliverable_item_date_code)].markAsTouched()"
                              [placeholder]='deliverableDates.deliverable_item_date_name' floatLabelType='Auto'
                              cssClass="e-filled">
              </ejs-datepicker> -->
              <ejs-datetimepicker [formControlName]="strReplace(deliverableDates.deliverable_item_date_code)" format="dd-MMM-yyyy hh:mm a"
                                  [allowEdit]=false
                                  (change)="onDateChange($event, strReplace(deliverableDates.deliverable_item_date_code), deliverableNoteForm, deliverableItemDateForm)"
                                  (blur)="deliverableItemDateForm.controls[strReplace(deliverableDates.deliverable_item_date_code)].markAsTouched()"
                                  [placeholder]='deliverableDates.deliverable_item_date_name' floatLabelType='Auto'
                                  cssClass="e-filled" [value]="dateValue">
              </ejs-datetimepicker>
              <div
                *ngIf="deliverableItemDateForm.controls[strReplace(deliverableDates.deliverable_item_date_code)].errors">
                <div
                  *ngIf="deliverableItemDateForm.controls[strReplace(deliverableDates.deliverable_item_date_code)].errors.required && deliverableItemDateForm.controls[strReplace(deliverableDates.deliverable_item_date_code)].touched "
                  class="e-error">
                  {{deliverableDates.deliverable_item_date_name}} is required
                </div>
              </div>
            </div>

            <div [ngClass]="showRelative ? 'show' : 'hide'"
                 class="form-group sf-outline-input mb-10 align-self-center p-4">
              <ejs-radiobutton
                [formControlName]="'relative_selection_' + strReplace(deliverableDates.deliverable_item_date_code)"
                label="" [name]="strReplace(deliverableDates.deliverable_item_date_code)"
                (checkedChange)="disableFields($event, 'relativeSelection',  deliverableDates.deliverable_item_date_code, deliverableItemDateForm)"
                cssClass="e-filled e-small"></ejs-radiobutton>
            </div>


            <div [ngClass]="showRelative ? 'show' : 'hide'"
                 class="form-group sf-outline-input mb-10 align-self-center pl-4">
              <ejs-numerictextbox
                [formControlName]="'relative_days_' + strReplace(deliverableDates.deliverable_item_date_code)"

                cssClass="e-filled" placeholder="Relative to (days)" format='n0' validateDecimalOnType="true"
                decimals="0" [min]="minVal" [max]="maxVal"
                (change)="setRelativeDays($event, deliverableItemDateForm, deliverableDates.deliverable_item_date_code); setRelativeDate(deliverableDates.deliverable_item_date_code, deliverableItemDateForm)">
              </ejs-numerictextbox>

              <div
                *ngIf="deliverableItemDateForm.controls['relative_days_'+ strReplace(deliverableDates.deliverable_item_date_code)].errors">
                <div
                  *ngIf="deliverableItemDateForm.controls['relative_days_'+ strReplace(deliverableDates.deliverable_item_date_code)].errors.required && deliverableItemDateForm.controls['relative_days_'+ strReplace(deliverableDates.deliverable_item_date_code)].touched "
                  class="e-error">
                  Relative to (days) is required
                </div>
              </div>
            </div>

            <div [ngClass]="showRelative ? 'show' : 'hide'"
                 class="form-group sf-outline-input mb-10 align-self-center pl-4">
              <ejs-dropdownlist
                [formControlName]="'relative_to_'+ strReplace(deliverableDates.deliverable_item_date_code)"
                [dataSource]='deliverableItemDatesFilter(deliverableDates.deliverable_item_date_code, deliverableItemDateForm)'
                floatLabelType="Auto"
                (change)="setRelativeTo($event, deliverableItemDateForm, deliverableDates.deliverable_item_date_code); setRelativeDate(deliverableDates.deliverable_item_date_code, deliverableItemDateForm)"
                [fields]='deliverableItemDateFields' cssClass="e-filled" placeholder='Date' [allowFiltering]='true'>
              </ejs-dropdownlist>
              <div
                *ngIf="deliverableItemDateForm.controls['relative_to_'+ strReplace(deliverableDates.deliverable_item_date_code)].errors">
                <div
                  *ngIf="deliverableItemDateForm.controls['relative_to_'+ strReplace(deliverableDates.deliverable_item_date_code)].errors.required && deliverableItemDateForm.controls['relative_to_'+ strReplace(deliverableDates.deliverable_item_date_code)].touched "
                  class="e-error">
                  Relative date is required
                </div>
              </div>

            </div>


            <div class="form-group sf-outline-input mb-10 mr-2 ml-2" [ngClass]="showRelative ? 'w-25' : 'w-50'">
              <ejs-dropdownlist
                [formControlName]="'date_owner_code_'+ strReplace(deliverableDates.deliverable_item_date_code)"
                [dataSource]='users' floatLabelType="Auto" [fields]='userFields' cssClass="e-filled"
                 placeholder='Date Owner' [allowFiltering]='true'
                (blur)="deliverableItemDateForm.controls['date_owner_code_'+ strReplace(deliverableDates.deliverable_item_date_code)].markAsTouched()"(open)="Complete($event)">
                <ng-template  #itemTemplate="" let-data style="display: inline-block">
                  <div style="display: inline-block" *ngIf="data.is_disable_yn !=1">
                    <span>{{data.first_name}}</span>
                  </div>
                  <div style="display: inline-block" *ngIf="data.is_disable_yn ==1" >
                    <span [ngStyle]="{'color':'#d3d3d3'}">{{data.first_name}}</span>
                  </div>

                </ng-template>
              </ejs-dropdownlist>

            </div>

            <div class="form-group sf-outline-input mb-10 align-self-center">
              <div class="d-flex">
                <img width="25px" class="mr-3" src="assets\media\can-tentative.png">
                <div class="mt-1">
                  <ejs-checkbox
                    [formControlName]="'is_tentative_' + strReplace(deliverableDates.deliverable_item_date_code)"
                    #wcheckbox label="" labelPosition="Before"></ejs-checkbox>
                </div>
              </div>

            </div>
          </div>
        </div>


        <div class="d-flex">
          <div class="form-group sf-outline-input mb-10 align-self-center pr-4"
               [ngClass]="showRelative ? 'show' : 'hide'">
            <ejs-radiobutton formControlName='date_selection_publishing_date' label="" name="publishing_date"
                             (checkedChange)="disableFields($event, 'dateSelection', 'publishing-date', deliverableItemDateForm)"
                             cssClass="e-filled e-small"></ejs-radiobutton>
          </div>

          <div class="form-group sf-outline-input mx-3 mr-4" [ngClass]="showRelative ? 'w-25' : 'w-50'">
            <!-- <ejs-datepicker formControlName="publishing_date" openOnFocus="true" [allowEdit]=false
                            (blur)="deliverableItemDateForm.controls.publishing_date.markAsTouched()"
                            (change)="onDateChange($event, 'publishing_date', deliverableNoteForm, deliverableItemDateForm)"
                            placeholder='Publishing Date *' floatLabelType='Auto' cssClass="e-filled">
            </ejs-datepicker> -->
            <ejs-datetimepicker formControlName="publishing_date" [allowEdit]=false  format="dd-MMM-yyyy hh:mm a"
                                (blur)="deliverableItemDateForm.controls.publishing_date.markAsTouched()"
                                (change)="onDateChange($event, 'publishing_date', deliverableNoteForm, deliverableItemDateForm)"
                                placeholder='Publishing Date' floatLabelType='Auto' cssClass="e-filled">
            </ejs-datetimepicker>
            <div *ngIf="deliverableItemDateForm.controls.publishing_date.errors">
              <div
                *ngIf="deliverableItemDateForm.controls.publishing_date.errors.required && deliverableItemDateForm.controls.publishing_date.touched "
                class="e-error">
                Publishing Date is required
              </div>
            </div>
          </div>

          <div class="form-group sf-outline-input mb-10 align-self-center p-4"
               [ngClass]="showRelative ? 'show' : 'hide'">
            <ejs-radiobutton formControlName="relative_selection_publishing_date" label="" name="publishing_date"
                             (checkedChange)="disableFields($event, 'relativeSelection',  'publishing-date', deliverableItemDateForm)"
                             cssClass="e-filled e-small"></ejs-radiobutton>
          </div>


          <div class="form-group sf-outline-input mb-10 align-self-center pl-4"
               [ngClass]="showRelative ? 'show' : 'hide'">
            <ejs-numerictextbox formControlName="relative_days_publishing_date" validateDecimalOnType="true"
                                decimals="0" cssClass="e-filled" placeholder="Relative to (days)" format='n0'
                                (change)="setRelativeDays($event, deliverableItemDateForm, 'publishing-date'); setRelativeDate('publishing-date', deliverableItemDateForm)">
            </ejs-numerictextbox>

            <div *ngIf="deliverableItemDateForm.controls['relative_days_publishing_date'].errors">
              <div
                *ngIf="deliverableItemDateForm.controls['relative_days_publishing_date'].errors.required && deliverableItemDateForm.controls['relative_days_publishing_date'].touched "
                class="e-error">
                Relative to (days) is required
              </div>
            </div>
          </div>

          <div class="form-group sf-outline-input mb-10 align-self-center pl-4"
               [ngClass]="showRelative ? 'show' : 'hide'">
            <ejs-dropdownlist id='relative_to_PB' formControlName="relative_to_publishing_date"
                              [dataSource]='deliverableItemDatesFilter("publishing-date", deliverableItemDateForm)'
                              floatLabelType="Auto"
                              (change)="setRelativeTo($event, deliverableItemDateForm, 'publishing-date'); setRelativeDate('publishing-date', deliverableItemDateForm)"
                              [fields]='deliverableItemDateFields' cssClass="e-filled" placeholder='Date'
                              [allowFiltering]='true'>
            </ejs-dropdownlist>
            <div *ngIf="deliverableItemDateForm.controls['relative_to_publishing_date'].errors">
              <div
                *ngIf="deliverableItemDateForm.controls['relative_to_publishing_date'].errors.required && deliverableItemDateForm.controls['relative_to_publishing_date'].touched "
                class="e-error">
                Relative date is required
              </div>
            </div>
          </div>

          <div class="form-group sf-outline-input mb-10 ml-2 mr-2" [ngClass]="showRelative ? 'w-25' : 'w-50'">
            <ejs-dropdownlist id='date_owner_code_publishing_date'
                              formControlName="date_owner_code_publishing_date" [dataSource]='users'
                              floatLabelType="Auto"
                              [fields]='userFields' cssClass="e-filled"  placeholder='Date Owner'
                              [allowFiltering]='true'
                              (blur)="deliverableItemDateForm.controls.date_owner_code_publishing_date.markAsTouched()" (open)="Complete($event)">
                              <ng-template  #itemTemplate="" let-data style="display: inline-block">
                                <div style="display: inline-block" *ngIf="data.is_disable_yn !=1">
                                  <span>{{data.first_name}}</span>
                                </div>
                                <div style="display: inline-block" *ngIf="data.is_disable_yn ==1" >
                                  <span [ngStyle]="{'color':'#d3d3d3'}">{{data.first_name}}</span>
                                </div>

                              </ng-template>
            </ejs-dropdownlist>

          </div>

          <div class="form-group sf-outline-input mb-10 align-self-center">
            <div class="d-flex">
              <img width="25px" class="mr-3" src="assets\media\can-tentative.png">
              <div class="mt-1">
                <ejs-checkbox formControlName="is_tentative_publishing_date" #wcheckbox label="" labelPosition="Before">
                </ejs-checkbox>
              </div>
            </div>
          </div>
        </div>

      </div>


    </form>


    <form [formGroup]="deliverableNoteForm" #formDir="ngForm" class="form-horizontal" novalidate="">

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="note" [multiline]="true" placeholder="Note" cssClass="e-filled" formControlName="note"
                     floatLabelType="Auto" (blur)="deliverableNoteForm.controls.note.markAsTouched()"></ejs-textbox>
        <div *ngIf="deliverableNoteForm.controls.note.errors">
          <div *ngIf="deliverableNoteForm.controls.note.errors.required && deliverableNoteForm.controls.note.touched"
               class="e-error">
            Notes is required
          </div>
        </div>
      </div>

    </form>

    <div *ngIf="deliverableForm.invalid || deliverableItemDateForm.invalid" class="e-error">Please fill all fields</div>
    <div *ngIf="error$ | async as error" class="e-error">{{error.error.message}}</div>

    <h2 *ngIf="deliverableInput">Notes</h2>
    <ejs-grid *ngIf="deliverableInput" #grid [allowPaging]="true" height="200" allowMultiSorting="false"
              [allowSorting]="true" allowTextWrap="true" (created)="isCreated()" class="border-0">
      <e-columns>
        <e-column textAlign='Left' width="55">
          <ng-template #template let-project>
            <span *ngIf="project.updated_by" [title]="(project.updated_by.first_name)">
              <span class="symbol symbol-lg-35 symbol-25 symbol-light-success" *ngIf="project.updated_by">
                <span class="symbol-label font-size-h5 font-weight-bold">{{project.updated_by.first_name |
                  firstLetter}}</span>
              </span>
            </span>
          </ng-template>
        </e-column>
        <e-column field='updated_at' headerText='Last Updated' textAlign='Left' width="170">
          <ng-template #template let-project>
            {{project.updated_at | formatDate:'MMM DD, YYYY HH:mm'}}
          </ng-template>
        </e-column>
        <e-column field="deliverable_note" headerText='Notes' textAlign='Left'></e-column>
        <!--          <e-column headerText='Action' textAlign='Center' width="100">-->
        <!--            <ng-template #template let-data>-->
        <!--              <div>-->
        <!--                <button ejs-dropdownbutton [items]='items' class="btn btn-icon btn-light btn-hover-primary btn-sm"-->
        <!--                        cssClass='e-caret-hide' (select)='select($event)'>-->
        <!--                  <mat-icon svgIcon="can-more-actions"-->
        <!--                            class="svg-icon svg-icon-md svg-icon-primary h-auto"></mat-icon>-->
        <!--                </button>-->
        <!--              </div>-->
        <!--            </ng-template>-->
        <!--          </e-column>-->
      </e-columns>
    </ejs-grid>


    <div class="my-10 pb-20">
      <button id="resetbtn"
              class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
              type="button" (click)="clear()" data-ripple="true">Clear
      </button>
      <button *ngIf="!deliverableInput" id="addSubmit"
              class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
              (click)="onAddDeliverable(deliverableForm, deliverableNoteForm)" [disabled]="formSent" data-ripple="true">
        Add Deliverable
      </button>
      <button *ngIf="deliverableInput" id="updateSubmit"
              class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
              (click)="onUpdateDeliverable(deliverableForm, deliverableNoteForm)" [disabled]="formSent"
              data-ripple="true">Update Deliverable
      </button>
    </div>


  </div>
</ejs-sidebar>

<ejs-dialog #endDateModal [buttons]='dlgButtons' header='Warning!' animationSettings='none' showCloseIcon='true'
            width='25%' [visible]='false'>
  <ng-template #content>
    <p>The end date is before the publishing date. Do you want to set the end date same as the publishing date?</p>
  </ng-template>
</ejs-dialog>
<ejs-dialog #clearModal [buttons]='dltButtons' header='Are you sure?' animationSettings='none' showCloseIcon='true'
            width='25%' [visible]='false'>
  <ng-template #content>
    <p>This action will clear all form fields.</p>
  </ng-template>
</ejs-dialog>
