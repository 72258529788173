import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import * as _ from "lodash";
import {
  companyNameValidator,
  emailValidator,
  specialCharacterValidator,
  telephoneValidator,
  trimValidator,
  websiteValidator,
} from "../../../../app-validators";
import { Company } from "../../../../core/models/company.model";
import { BehaviorSubject, Observable, of } from "rxjs";
import {
  CompanyAdd,
  CompanySetErrorNull,
  CompanySetStatusNull,
  CompanyUpdate,
  GetCompanyActivity,
} from "../../../../core/store/actions/company.actions";
import { select, Store } from "@ngrx/store";
import { AppState } from "../../../../core";
import { EmitType } from "@syncfusion/ej2-base";
import {
  DropDownListComponent,
  FilteringEventArgs,
  MultiSelectComponent,
  SelectEventArgs,
} from "@syncfusion/ej2-angular-dropdowns";
import { Query } from "@syncfusion/ej2-data";
import { Tags } from "../../../../core/models/tags.model";
import { SidebarComponent } from "@syncfusion/ej2-angular-navigations";
import { CompanyService } from "../../../../core/store/services/company.service";
import { map } from "rxjs/operators";
import * as moment from "moment";
import {
  ButtonPropsModel,
  DialogComponent,
} from "@syncfusion/ej2-angular-popups";
import { TabComponent } from "@syncfusion/ej2-angular-navigations";
import { AdminService } from "src/app/core/store/services/admin.service";
import { User } from "src/app/core/models/user.model";
import { LoadUsers } from "src/app/core/store/actions/common.actions";
import cloneDeep from "lodash.clonedeep";
import { Router } from "@angular/router";
import { TextBoxComponent } from "@syncfusion/ej2-angular-inputs";

@Component({
  selector: "app-company-form",
  templateUrl: "./company.component.html",
  styleUrls: ["./company.component.scss"],
})
export class CompanyComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild("sidebarInstance") public sidebarInstance: SidebarComponent;
  @ViewChild("industryType") public industryType: MultiSelectComponent;
  @ViewChild("visibility") visibilityDropdown: DropDownListComponent;
  @ViewChild("manager") public manager!: DropDownListComponent;
  @ViewChild("owner") public owner!: DropDownListComponent;
  @ViewChild("email") public email: TextBoxComponent;
  @ViewChild("primaryCommunityDropdown")
  public primaryDropdown!: DropDownListComponent;
  @ViewChild("clearModal") public clearModal: DialogComponent;
  @ViewChild("tabObj")
  public tabObj?: TabComponent;
  @Input() users: any = [];
  @Input() Ownerusers: any = [];
  @Input() Mnagerusers: any = [];
  @Input() disableUser;
  @Input() sManager: any;
  @Input() sOwner: any;
  @Input() companyTypes: any;
  @Input() communityTypes: any;
  @Input() isSuperAdmin: any;
  @Input() loggedInUser: any;
  @Input() onlyActiveUser: any;
  @Input() allTags: Observable<Array<Tags>>;
  @Input() countries: { [key: string]: object }[];
  @Output() valueChange = new EventEmitter();
  @Output() editChnage = new EventEmitter();

  companyName: any;
  companyForm: FormGroup;
  showUploadingBtn = false;
  showButton = true;
  setManager = null;
  setOwner = null;
  @ViewChild("tags") companyTag: MultiSelectComponent;

  public dltButtons: ButtonPropsModel[] = [
    {
      click: this.clearAll.bind(this),
      buttonModel: { content: "Yes", isPrimary: true },
    },
    {
      click: this.hideClearAllModal.bind(this),
      buttonModel: { content: "Cancel", isPrimary: false },
    },
  ];
  public width = "auto";
  public position = "Right";
  selectedTag = [];
  tagSelected = "";
  imgFile: string;
  companyLogoReferenceID = "";
  allowEdit = false;
  isManagerErro = false;
  isOwnerError = false;
  preSelectedFiles = [];
  selectedCommunityTypesObs = new BehaviorSubject([]);
  selectedCommunityTypes: any[] = [];
  ownerChanged = false;
  managerChanged = false;
  notFormValid = true;
  managerValue = "";
  ownerValue = "";
  isEdit = false;
  // preSelectedFiles$ = new BehaviorSubject([]);
  public value: string;
  public isBool = false;
  errorMsg: any;
  error$: Observable<any>;
  success$: Observable<string>;
  public formSent: boolean;
  duplicateMsg$ = new BehaviorSubject("");
  oldManager = null;
  oldOwner = null;
  url: any;
  public setCleared$: BehaviorSubject<boolean | null> = new BehaviorSubject<
    boolean | null
  >(false);
  preLoadedFiles: any;
  companyFirstEdit = true;
  userCode;
  disabledUser = [];
  activeUser = [];
  user$: Observable<User>;
  userDetails: any;
  allowedEmailAccountRoles = ["super-admin"];
  public industryFields: object;
  public tagFields: object = { value: "tag", text: "tag" };
  public communityTypeField: any = {
    value: "community_type_code",
    text: "community_type_name",
  };
  public countryFields: object = {
    value: "country_code",
    text: "country_name",
  };
  public userFields: object = {
    dataSource: this.Ownerusers,
    value: "user_code",
    text: "first_name",
    itemCreated: (item) => {
      if (item.curData.is_disable_yn === 1) {
        item.item.style.color = "#d3d3d3";
      }
    },
  };
  public userField: object = {
    dataSource: this.Mnagerusers,
    value: "user_code",
    text: "first_name",
    itemCreated: (item) => {
      if (item.curData.is_disable_yn === 1) {
        item.item.style.color = "#d3d3d3";
      }
    },
  };
  public visibilityFields: object = {
    value: "visibility_code",
    text: "visibility_name",
  };
  // tslint:disable-next-line:variable-name
  public _companyInput$: BehaviorSubject<Company | null> =
    new BehaviorSubject<Company | null>(null);
  public tagModelData = [];
  modelData = [];
  displayPasswordInput = false;

  public selectedItem = "public";
  selectedPrimaryCommunityType;

  public visibilityList: any[] = [
    { visibility_code: "private", visibility_name: "Private" },
    {
      visibility_code: "password_protected",
      visibility_name: "Password Protected",
    },
    { visibility_code: "public", visibility_name: "Public" },
  ];
  public headerText: any[] = [{ text: "Company Form" }, { text: "Notes" }];
  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    event: KeyboardEvent,
  ) {
    if (this.sidebarInstance.isOpen) {
      this.closeSidebar();
    }
  }

  @Input() set companyInput(mode: Company | null) {
    this._companyInput$.next(mode);
  }

  get companyInput(): Company | null {
    return this._companyInput$.getValue();
  }

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private companyService: CompanyService,
    private adminService: AdminService,
    private router: Router,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this._companyInput$.getValue()) {
      if (this._companyInput$.getValue().company_logo_reference_id) {
        this.preLoadedFiles = [
          {
            filename: this._companyInput$.getValue().company_logo_filename,
            url: this._companyInput$.getValue().company_logo_reference_id,
          },
        ];
      }
      // tslint:disable-next-line:variable-name
      const community_type_array = [];
      if (this._companyInput$.getValue().community_type) {
        for (const companyType of this._companyInput$.getValue()
          .community_type) {
          community_type_array.push(companyType.community_type_code);
        }
      }
      if (!this.companyForm) {
        this.generateCompanyForm();
      }
      // tslint:disable-next-line:variable-name
      const company_tags_array = [];
      if (this._companyInput$.getValue().company_tag) {
        for (const tag of this._companyInput$.getValue().company_tag) {
          company_tags_array.push(tag.tag);
        }
      }
      // tslint:disable-next-line:variable-name
      const primary_community_type_array = [];
      if (this._companyInput$.getValue().primary_community_type) {
        for (const companyType of this._companyInput$.getValue()
          .primary_community_type) {
          primary_community_type_array.push(companyType.community_type_code);
        }
      }
      this.companyForm.patchValue({
        companyName: this._companyInput$.getValue().company_name
          ? this._companyInput$.getValue().company_name
          : null,
        display_name: this._companyInput$.getValue().display_name
          ? this._companyInput$.getValue().display_name
          : null,
        accountOwner: this._companyInput$.getValue().account_owner
          ? this._companyInput$.getValue().account_owner.user_code
          : null,
        account_manager: this._companyInput$.getValue().account_manager
          ? this._companyInput$.getValue().account_manager.user_code
          : null,
        industryType: community_type_array ? community_type_array : null,
        telephone: this._companyInput$.getValue().tel_no
          ? this._companyInput$.getValue().tel_no
          : null,
        website: this._companyInput$.getValue().website
          ? this._companyInput$.getValue().website
          : null,
        address: this._companyInput$.getValue().address
          ? this._companyInput$.getValue().address
          : null,
        city: this._companyInput$.getValue().city_name
          ? this._companyInput$.getValue().city_name
          : null,
        country: this._companyInput$.getValue().country.country_code
          ? this._companyInput$.getValue().country.country_code
          : null,
        tags: this._companyInput$.getValue().company_tag
          ? company_tags_array
          : null,
        excerpt: this._companyInput$.getValue().excerpt
          ? this._companyInput$.getValue().excerpt
          : null,
        email: this._companyInput$.getValue().email
          ? this._companyInput$.getValue().email
          : null,
        slug: this._companyInput$.getValue().slug
          ? this._companyInput$.getValue().slug
          : null,
        member_since: this._companyInput$.getValue().member_since
          ? moment(
              this._companyInput$.getValue().member_since,
              "YYYY-MM-DD",
            ).format("MM/DD/YYYY")
          : null,
        p_o_box: this._companyInput$.getValue().p_o_box
          ? this._companyInput$.getValue().p_o_box
          : null,
        visibility: this._companyInput$.getValue().visibility
          ? this._companyInput$.getValue().visibility
          : null,
        password_protected_password: this._companyInput$.getValue()
          .password_protected_password
          ? this._companyInput$.getValue().password_protected_password
          : null,
        // companyLogo: null
      });
      this.oldOwner = this._companyInput$.getValue().account_owner
        ? this._companyInput$.getValue().account_owner.user_code
        : null;
      this.oldManager = this._companyInput$.getValue().account_manager
        ? this._companyInput$.getValue().account_manager.user_code
        : null;
      if (this._companyInput$.getValue().company_logo_reference_id) {
        this.companyLogoReferenceID =
          this._companyInput$.getValue().company_logo_reference_id;
      }
    }
    if (changes.companyTypes) {
      this.industryFields = {
        dataSource: this.companyTypes,
        value: "company_type_code",
        text: "company_type_name",
        child: "children",
      };
    }
    if (!this._companyInput$) {
      if (this.companyForm) {
        this.companyForm.controls.accountOwner.setValue(this.loggedInUser);
      }
    }
    this.isBool = false;
    try {
      (this.tabObj as TabComponent).select(0);
    } catch (e) {}
    try {
      (this.tabObj as TabComponent).hideTab(1, this.isBool);
    } catch (e) {}
  }

  ngOnInit(): void {
    if (!this.companyForm) {
      this.generateCompanyForm();
      if (this._companyInput$.getValue() == null) {
        if (this.companyForm) {
          this.companyForm.controls.accountOwner.setValue(this.loggedInUser);
        }
      }
    }

    if (this.allTags) {
      this.allTags.subscribe((data) => {
        this.tagModelData = data;
      });
    }
    this.error$ = this.store.pipe(select((store) => store.companies.error));
    this.error$.subscribe((data: any) => {
      if (data) {
        this.errorMsg = data.error.message;
        this.formSent = false;
        this.ownerChanged = false;
        this.notFormValid = true;
        this.managerChanged = false;
        this.isOwnerError = false;
        this.isManagerErro = false;
        this.isEdit = false;
        (this.tabObj as TabComponent)?.select(0);
        this.isBool = false;
        (this.tabObj as TabComponent).hideTab(1, this.isBool);
        this.isBool = false;
      }
    });

    this.success$ = this.store.pipe(
      select((store) => store.companies.success.isForm),
    );
    this.success$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
        console.log(data);
        this.closeSidebar();
        this.ownerChanged = false;
        this.managerChanged = false;
        this.isOwnerError = false;
        this.isManagerErro = false;
        this.notFormValid = true;
        (this.tabObj as TabComponent).select(0);
        this.isBool = false;
        (this.tabObj as TabComponent).hideTab(1, this.isBool);
        this.isBool = false;
        this.store.dispatch(new CompanySetStatusNull());
        console.log(data);
        if (data === "UPDATE_COMPANY") {
          this.store.dispatch(
            new GetCompanyActivity({
              companyCode: this._companyInput$.getValue().company_code,
              entity: "company",
            }),
          );
        }
      }
    });
  }

  onInputChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    let value = inputElement.value;
    const patternP = new RegExp('^\\+?[0-9]*$');    
    const pattern0 = new RegExp('[^0-9]');
    if (value.startsWith('+')) {
      value = '+' + value.slice(1).replace(pattern0, '');
    } else {
      value = value.replace(pattern0, '');
    }
    inputElement.value = value;
    this.companyForm?.controls?.telephone?.setValue(value);
  }

  onPaste(event: ClipboardEvent): void {
    const inputElement = event.target as HTMLInputElement;  
    event.preventDefault();  
    const pastedData = event.clipboardData?.getData('text') || '';  
    const sanitizedValue = pastedData.startsWith('+')
      ? '+' + pastedData.slice(1).replace(/[^0-9]/g, '')
      : pastedData.replace(/[^0-9]/g, '');  
    inputElement.value = sanitizedValue;
    this.companyForm?.controls?.telephone?.setValue(sanitizedValue);
  }  

  generateCompanyForm() {
    this.companyForm = this.formBuilder.group({
      companyName: new FormControl("", [
        Validators.required,
        Validators.compose([trimValidator]),
      ]),
      display_name: new FormControl("", [Validators.maxLength(100)]),
      accountOwner: new FormControl(""),
      account_manager: new FormControl(""),
      industryType: new FormControl(""),
      telephone: new FormControl("", [
        // Validators.compose([telephoneValidator]),
        Validators.maxLength(50),
      ]),
      website: new FormControl("", [
        Validators.compose([websiteValidator]),
        Validators.maxLength(200),
      ]),
      address: new FormControl("", [Validators.maxLength(200)]),
      city: new FormControl("", [
        Validators.required,
        Validators.maxLength(100),
        Validators.compose([trimValidator]),
      ]),
      country: new FormControl("", [Validators.required]),
      tags: new FormControl(""),
      excerpt: new FormControl(""),
      email: new FormControl("", [Validators.compose([emailValidator])]),
      slug: new FormControl("", [
        Validators.maxLength(200),
        Validators.required,
        Validators.compose([Validators.pattern("^[A-Za-z0-9-]+$")]),
      ]),
      member_since: new FormControl(""),
      p_o_box: new FormControl(""),
      companyLogo: new FormControl(""),
      // visibility: new FormControl('', Validators.required),
      // password_protected_password: new FormControl('', Validators.maxLength(255)),
      primary_community_type: new FormControl(""),
      account_owner_activity_note: new FormControl(null, [
        Validators.maxLength(1000),
      ]),
      account_manager_activity_note: new FormControl(null, [
        Validators.maxLength(1000),
      ]),
    });
  }

  onAddCompany(form: FormGroup) {
    console.log(this.ownerChanged, this.managerChanged, this.notFormValid);
    if (this.ownerChanged === true || this.managerChanged === true) {
      this.notFormValid = false;
      if (this.ownerChanged === true) {
        if (
          this.ownerValue == null ||
          this.ownerValue === "" ||
          this.ownerValue === undefined
        ) {
          this.isOwnerError = true;
        } else {
          this.isOwnerError = false;
        }
      }
      if (this.managerChanged === true) {
        if (
          this.managerValue == null ||
          this.managerValue === "" ||
          this.managerValue === undefined
        ) {
          this.isManagerErro = true;
        } else {
          this.isManagerErro = false;
        }
      }
    }
    if (this.isSuperAdmin) {
      if (this.isManagerErro === false && this.isOwnerError === false) {
        this.notFormValid = true;
      } else {
        this.notFormValid = false;
      }
    } else {
      if (this.isManagerErro === false) {
        this.notFormValid = true;
      } else {
        this.notFormValid = false;
      }
    }
    this.companyName = this.companyForm.value.companyName.trim();
    console.log(this.ownerChanged, this.managerChanged, this.notFormValid);
    if (!form.invalid && this.notFormValid === true) {
      this.formSent = true;
      this.store.dispatch(
        new CompanyAdd({
          company_name: this.companyForm.value.companyName.trim(),
          display_name: this.companyForm.value.display_name
            ? this.companyForm.value.display_name.trim()
            : null,
          address: this.companyForm.value.address,
          city_name: this.companyForm.value.city,
          country: this.companyForm.value.country,
          website: this.companyForm.value.website,
          tel_no: this.companyForm.value.telephone,
          industry_type: _.cloneDeep(this.companyForm.value.industryType),
          accountOwner: form.value.accountOwner,
          account_manager: form.value.account_manager,
          company_tag: form.value.tags,
          excerpt: this.companyForm.value.excerpt,
          email: this.companyForm.value.email,
          slug: this.companyForm.value.slug,
          member_since: this.companyForm.value.member_since
            ? moment(this.companyForm.value.member_since).format("YYYY-MM-DD")
            : null,
          p_o_box: this.companyForm.value.p_o_box,
          company_logo_reference_id: this.companyLogoReferenceID,
          primary_community_type_code:
            this.companyForm.value.primary_community_type,
          account_owner_activity_note:
            this.companyForm.value.account_owner_activity_note,
          account_manager_activity_note:
            this.companyForm.value.account_manager_activity_note,
        }),
      );
      (this.tabObj as TabComponent).select(0);
      this.isBool = false;
      (this.tabObj as TabComponent).hideTab(1, this.isBool);
      this.managerValue = "";
      this.ownerValue = "";
      this.ownerChanged = false;
      this.managerChanged = false;
      this.isOwnerError = false;
      this.isManagerErro = false;
    }
  }

  onUpdateCompany(form: FormGroup) {
    if (this.ownerChanged === true || this.managerChanged === true) {
      this.notFormValid = false;
      if (this.ownerChanged === true) {
        if (
          this.ownerValue == null ||
          this.ownerValue === "" ||
          this.ownerValue === undefined
        ) {
          this.isOwnerError = true;
        } else {
          this.isOwnerError = false;
        }
      }
      if (this.managerChanged === true) {
        if (
          this.managerValue == null ||
          this.managerValue === "" ||
          this.managerValue === undefined
        ) {
          this.isManagerErro = true;
        } else {
          this.isManagerErro = false;
        }
      }
    }
    if (this.isSuperAdmin) {
      if (this.isManagerErro === false && this.isOwnerError === false) {
        this.notFormValid = true;
      } else {
        this.notFormValid = false;
      }
    } else {
      if (this.isManagerErro === false) {
        this.notFormValid = true;
      } else {
        this.notFormValid = false;
      }
    }
    const company = {
      company_code: this._companyInput$.getValue().company_code,
      display_name: form.value.display_name,
      company_name: form.value.companyName,
      address: form.value.address,
      city_name: form.value.city,
      country_code: form.value.country,
      website: form.value.website,
      tel_no: form.value.telephone,
      community_type_code: _.cloneDeep(form.value.industryType),
      account_owner: form.value.accountOwner,
      account_manager: form.value.account_manager,
      company_tag: form.value.tags,
      excerpt: form.value.excerpt,
      is_inactive_yn: this._companyInput$.getValue().is_inactive_yn,
      is_published_yn: this._companyInput$.getValue().is_published_yn,
      email: form.value.email,
      slug: form.value.slug,
      member_since: form.value.member_since
        ? moment(form.value.member_since).format("YYYY-MM-DD")
        : null,
      p_o_box: form.value.p_o_box,
      company_logo_reference_id: this.companyLogoReferenceID,
      description: this._companyInput$.getValue().description,
      password_protected_password:
        this._companyInput$.getValue().password_protected_password,
      primary_community_type_code:
        this.companyForm.value.primary_community_type,
      account_owner_activity_note:
        this.companyForm.value.account_owner_activity_note,
      account_manager_activity_note:
        this.companyForm.value.account_manager_activity_note,
    };

    if (!form.invalid && this.notFormValid === true) {
      this.formSent = true;
      this.isEdit = true;
      this.store.dispatch(
        new CompanyUpdate({
          index: this._companyInput$.getValue().company_code,
          company,
        }),
      );
      (this.tabObj as TabComponent).select(0);
      this.isBool = false;
      (this.tabObj as TabComponent).hideTab(1, this.isBool);
      this.managerValue = "";
      this.ownerValue = "";
      this.ownerChanged = false;
      this.managerChanged = false;
      this.sManager = form.value.accountOwner;
      this.sOwner = form.value.account_manager;
    }
  }

  public onFiltering: EmitType<any> = (e: FilteringEventArgs) => {
    let query = new Query();
    query =
      e.text !== ""
        ? query.where("country_name", "startswith", e.text, true)
        : query;
    e.updateData(this.countries, query);
  };

  ngOnDestroy() {
    this._companyInput$.unsubscribe();
    this.duplicateMsg$.unsubscribe();
    this.selectedCommunityTypesObs.unsubscribe();
    this.companyName = undefined;
  }

  openSidebar(): void {
    this.sidebarInstance.show();
    this.managerValue = "";
    this.ownerValue = "";
    this.notFormValid = true;
    this.ownerChanged = false;
    this.managerChanged = false;
    this.isOwnerError = false;
    this.isManagerErro = false;
    this.isEdit = false;
    this.setCleared$.next(false);
    (this.tabObj as TabComponent).select(0);
    this.isBool = false;
    (this.tabObj as TabComponent).hideTab(1, this.isBool);

    if (this._companyInput$.getValue() == null) {
      if (this.companyForm) {
        this.companyForm.controls.accountOwner.setValue(this.loggedInUser);
      }
    }
    if (!this.companyInput) {
      this.companyForm.reset();
      this.companyForm.controls.accountOwner.setValue(this.loggedInUser);
    }
    this.companyName = undefined;
  }

  closeSidebar(): void {
    this.errorReset();
    this.companyForm.reset();
    this.managerValue = "";
    this.ownerValue = "";
    this.notFormValid = true;
    this.isBool = false;
    this.isOwnerError = false;
    this.isManagerErro = false;
    this.ownerChanged = false;
    this.managerChanged = false;
    this.isEdit = false;
    (this.tabObj as TabComponent).select(0);
    (this.tabObj as TabComponent).hideTab(1, this.isBool);
    this.ownerChanged = false;
    this.managerChanged = false;
    this.valueChange.emit("success");
    if (this.sidebarInstance && this.sidebarInstance.isOpen) {
      this.sidebarInstance.hide();
    }
    this.setCleared$.next(true);
    this.preLoadedFiles = [];
    this.companyFirstEdit = true;
  }

  onBlur(args, form) {
    this.tagSelected = this.selectedTag[this.selectedTag.length - 1];
    if (!this.modelData.includes(this.tagSelected)) {
      this.companyTag.addItem({
        tag: this.tagSelected,
        tag_code: this.tagSelected,
      });
      form.controls.tags.setValue(
        _.uniq(_.compact([...this.modelData, this.tagSelected])),
      );
    }
    this.modelData = _.compact([...this.modelData, this.tagSelected]);
    this.modelData.push(this.tagSelected);
    this.selectedTag = [];
  }

  syncModel = (event) => {
    this.modelData = event;
  };

  actionComplete(args) {
    if (_.get(args, "result[0]")) {
      this.selectedTag.push(args.result[0].tag);
    }
  }

  onChange(event) {
    const tagsArray = _.compact(this.modelData);
    if (tagsArray.length === 0) {
      this.modelData = [];
    }
  }

  select(args) {
    this.selectedTag = [];
  }

  errorReset() {
    this.store.dispatch(new CompanySetErrorNull());
  }

  checkDuplicates(companyName: string) {
    if (
      (this._companyInput$.getValue() &&
        this._companyInput$.getValue().company_name !== companyName.trim() &&
        companyName.trim() !== "") ||
      (!this._companyInput$.getValue() && companyName.trim() !== "")
    ) {
      this.companyService
        .checkDuplicates({ company_name: companyName })
        .subscribe(
          (data) => {
            if (_.size(data.data)) {
              this.duplicateMsg$.next(
                "Warning: We found " +
                  data.data.length +
                  " more company/companies with the same name!",
              );
            } else {
              this.duplicateMsg$.next("");
            }
          },
          (err) => {},
        );
    } else {
      this.duplicateMsg$.next("");
    }
  }

  getCompanySlug(companyName: string, form: FormGroup) {
    if (
      (this._companyInput$.getValue() &&
        this._companyInput$.getValue().company_name !== companyName.trim() &&
        companyName.trim() !== "") ||
      (!this._companyInput$.getValue() && companyName.trim() !== "")
    ) {
      this.companyService
        .getCompanySlug({ company_name: companyName })
        .subscribe(
          (data) => {
            if (data && data.data) {
              form.controls.slug.setValue(data.data);
            }
          },
          (err) => {},
        );
    }
  }

  setCompanyType(args, companyForm: FormGroup) {
    this.selectedCommunityTypes = [];
    this.selectedCommunityTypesObs.next([]);

    const communityTypes = args.value;
    this.companyForm.controls.industryType.setValue(args.value);
    if (communityTypes) {
      communityTypes.forEach((element) => {
        const foundIndex = this.communityTypes.findIndex(
          (item) => item.community_type_code === element,
        );

        if (foundIndex !== -1) {
          this.selectedCommunityTypes.push(this.communityTypes[foundIndex]);
        }
      });
    }

    if (this.selectedCommunityTypes.length > 0) {
      companyForm.controls.primary_community_type.setValidators(
        Validators.required,
      );
      companyForm.controls.primary_community_type.markAsTouched();
      companyForm.controls.primary_community_type.updateValueAndValidity();
    } else {
      companyForm.controls.primary_community_type.clearValidators();
      companyForm.controls.primary_community_type.updateValueAndValidity();
    }

    if (
      this.companyForm.controls.primary_community_type.value !== "" &&
      !this.selectedCommunityTypes.includes(
        this.companyForm.controls.primary_community_type.value,
      )
    ) {
      if(!this.companyForm.controls.primary_community_type.value){
        this.companyForm.controls.primary_community_type.setValue(null);
      }
    }
    this.selectedCommunityTypesObs.next(this.selectedCommunityTypes);
    if (this._companyInput$.getValue() && this.companyFirstEdit) {
      console.log(
        "this._companyInput$.getValue()",
        this._companyInput$.getValue(),
        this.selectedCommunityTypes,
      );
      try {
        const value =
          this._companyInput$.getValue()?.primary_community_type[0]
            .community_type_code;
        this.companyForm.controls.primary_community_type.setValue(value);
        this.selectedPrimaryCommunityType =
          this._companyInput$.getValue()?.primary_community_type[0].community_type_code;
      } catch (e) {
        if (
          this.selectedCommunityTypes &&
          this.selectedCommunityTypes.length === 1
        ) {
          const value = this.selectedCommunityTypes[0].community_type_code;
          this.companyForm.controls.primary_community_type.setValue(value);          
          this.selectedPrimaryCommunityType =
            this._companyInput$.getValue()?.primary_community_type[0].community_type_code;
        }
      }
      this.companyFirstEdit = false;
    }
  }

  updateReferenceID(args) {
    if (args.reference_id && args.isUploading === false) {
      this.companyLogoReferenceID = args.reference_id;
      this.formSent = false;
      this.showUploadingBtn = false;
    } else if (args.cleared === true) {
      this.formSent = false;
    } else if (args.isUploading === true) {
      this.formSent = true;
      this.showUploadingBtn = true;
    } else if (args.reference_id === "" && args.isUploading === false) {
      this.companyLogoReferenceID = args.reference_id;
      this.formSent = false;
      this.showUploadingBtn = false;
    }
  }

  onOpen(event) {
    for (const [index, communityType] of (
      this.industryType as any
    ).popupObj.element
      .querySelectorAll(".e-list-item")
      .entries()) {
      const foundVal = this.communityTypes.find(
        (item) => item.community_type_code === communityType.dataset.value,
      );
      if (foundVal.publish_status === "draft") {
        if (
          !(this.industryType as any).popupObj.element
            .querySelectorAll(".e-list-item")
            [index].classList.contains("e-hide")
        ) {
          (this.industryType as any).popupObj.element
            .querySelectorAll(".e-list-item")
            [index].classList.add("e-disabled");
        }
      }
    }
  }

  onSelect(args: SelectEventArgs) {
    if (args.item.classList.contains("e-hide")) {
      args.cancel = true;
    }
    const val: any = args.itemData;
    this.primaryDropdown.addItem(val);
  }
  createdPrimaryDropdown() {
    try {
      const value =
        this._companyInput$.getValue().primary_community_type[0]
          .community_type_code;
      this.companyForm.controls.primary_community_type.setValue(value);
      this.selectedPrimaryCommunityType =
        this._companyInput$.getValue().primary_community_type[0].community_type_code;
    } catch (e) {
      if (
        this.selectedCommunityTypes &&
        this.selectedCommunityTypes.length === 1
      ) {
        const value = this.selectedCommunityTypes[0].community_type_code;
        this.companyForm.controls.primary_community_type.setValue(value);
        this.selectedPrimaryCommunityType =
          this._companyInput$.getValue().primary_community_type[0].community_type_code;
      }
    }
  }
  clearAll() {
    this.clearModal.hide();
    if (this.companyForm) {
      this.companyForm.reset();
    }
    if (this.companyForm.controls.industryType) {
      this.companyForm.controls.industryType.setValue("");
    }
  }
  hideClearAllModal() {
    this.clearModal.hide();
  }
  clear() {
    this.clearModal.show();
  }
  accountMnagerChanged(event) {
    if (this.oldManager != null) {
      if (event.itemData && event.itemData.user_code !== this.oldManager) {
        this.managerChanged = true;
      } else if (event.itemData == null) {
        this.managerChanged = true;
      } else {
        this.managerChanged = false;
      }
    } else {
      if (event.itemData && event.itemData.user_code) {
        this.managerChanged = true;
      } else if (event.itemData == null) {
        this.managerChanged = false;
      }
    }
    if (this.managerChanged === true) {
      this.isBool = true;
      (this.tabObj as TabComponent).hideTab(1, this.isBool);
    } else {
      this.isBool = false;
      (this.tabObj as TabComponent).hideTab(1, this.isBool);
    }
  }
  accountOwnerChanged(event) {
    if (this.companyInput) {
      if (event.itemData) {
        if (event.itemData.user_code !== this.oldOwner) {
          this.ownerChanged = true;
          this.isBool = true;
          (this.tabObj as TabComponent).hideTab(1, this.isBool);
        } else {
          this.ownerChanged = false;
          this.isBool = false;
          (this.tabObj as TabComponent).hideTab(1, this.isBool);
        }
      } else {
        this.ownerChanged = false;
        this.isBool = false;
        (this.tabObj as TabComponent).hideTab(1, this.isBool);
      }
    } else {
      if (event.itemData) {
        if (event.itemData.user_code !== this.loggedInUser) {
          this.ownerChanged = true;
          this.isBool = true;
          (this.tabObj as TabComponent).hideTab(1, this.isBool);
        } else {
          this.ownerChanged = false;
          this.isBool = false;
          (this.tabObj as TabComponent).hideTab(1, this.isBool);
        }
      } else {
        this.ownerChanged = false;
        this.isBool = false;
        (this.tabObj as TabComponent).hideTab(1, this.isBool);
      }
    }
  }

  navigate(companyForm) {
    if (!companyForm.invalid) {
      (this.tabObj as TabComponent).select(1);
    }
  }
  drodownCreated() {
    this.manager.dataSource = this.Mnagerusers;
  }
  input(args) {
    this.managerValue = args.value;
  }
  inputs(args) {
    this.ownerValue = args.value;
  }

  dropdowncreated() {
    this.store.dispatch(new LoadUsers());
    this.store
      .select((store) => store.metadata.users)
      .subscribe((data: any) => {
        if (data) {
          this.activeUser = [];
          this.disableUser = [];
          setTimeout(() => {
            if (
              this.router.url.indexOf("/company/details") > -1 ||
              this.router.url.indexOf("/projects/details") > -1
            ) {
              if (this.sManager) {
                this.activeUser = [];
                this.disableUser = [];
                for (const user of data) {
                  if (
                    user.is_disable_yn == null ||
                    user.user_code === this.sManager.user_code
                  ) {
                    this.activeUser.push(user);
                  } else {
                    this.disabledUser.push(user);
                  }
                }
                this.Mnagerusers = [];
                this.Mnagerusers = this.activeUser;
              }
            }
          }, 1000);
          for (const user of data) {
            if (user.is_disable_yn == null) {
              this.activeUser.push(user);
            } else {
              this.disabledUser.push(user);
            }
          }
          this.Mnagerusers = [];
          this.Mnagerusers = this.activeUser;
        }
      });
    if (this.companyForm) {
    }
  }

  accountOwnerCreated(owner?) {
    console.log("owner");
    this.store.dispatch(new LoadUsers());
    this.store
      .select((store) => store.metadata.users)
      .subscribe((data: any) => {
        if (data) {
          this.activeUser = [];
          this.disableUser = [];
          setTimeout(() => {
            if (
              this.router.url.indexOf("/company/details") > -1 ||
              this.router.url.indexOf("/project/details") > -1
            ) {
              if (this.sOwner) {
                this.activeUser = [];
                this.disableUser = [];
                for (const user of data) {
                  if (
                    user.is_disable_yn == null ||
                    user.user_code === this.sOwner.user_code
                  ) {
                    this.activeUser.push(user);
                  } else {
                    this.disabledUser.push(user);
                    if (user.user_code === owner) {
                      this.activeUser.push(user);
                    }
                  }
                }
                this.Ownerusers = [];
                this.Ownerusers = this.activeUser;
              }
            }
          }, 1000);
          for (const user of data) {
            if (user.is_disable_yn == null) {
              this.activeUser.push(user);
            } else {
              this.disabledUser.push(user);
            }
          }
          this.Ownerusers = [];
          this.Ownerusers = this.activeUser;
        }
      });
  }
}
