import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, HostListener} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {BehaviorSubject, Observable} from 'rxjs';
import {Tags} from '../../../../../core/models/tags.model';
import {Store, select} from '@ngrx/store';
import {AppState} from '../../../../../core';
import {
  CompanyOrderDeliverablesFilter,
  CompanySetStatusNull,
  CompanyUpdateLTDSettings
} from '../../../../../core/store/actions/company.actions';
import * as _ from 'lodash';
import {emailValidator} from '../../../../../app-validators';
import {DropDownListComponent, MultiSelectComponent} from '@syncfusion/ej2-angular-dropdowns';
import {CommonService} from '../../../../../core/services/common.service';
import {FilteringEventArgs} from '@syncfusion/ej2-angular-dropdowns';
import {debounce, EmitType} from '@syncfusion/ej2-base';
import {Query} from '@syncfusion/ej2-data';
import {BrandService} from 'src/app/core/store/services/brand.service';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';
import { TextBoxComponent } from '@syncfusion/ej2-angular-inputs';
@Component({
  selector: 'app-company-lovethatdesign-form',
  templateUrl: './company-lovethatdesign.component.html',
  styleUrls: ['./company-lovethatdesign.component.scss']
})
export class CompanyLovethatdesignComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('sidebarInstance') public sidebarInstance: SidebarComponent;
  @ViewChild('companyFormAddress') companyFormAddress: MultiSelectComponent;
  @ViewChild('visibility') visibilityDropdown: DropDownListComponent;
  @ViewChild('clearModal') public clearModal: DialogComponent;
  @ViewChild('latitudeInput', { static: false }) latitudeInput!: TextBoxComponent;
  @ViewChild('longitudeInput', { static: false }) longitudeInput!: TextBoxComponent;
  @Input() brandList = [];
  @Input() defaultTabList = [];
  @Input() selectedCompanyCode;
  @Input() type = '';
  @Output() valueChanged = new EventEmitter();

  public dltButtons: ButtonPropsModel[] = [
    { click: this.clearAll.bind(this), buttonModel: { content: 'Yes', isPrimary: true, } },
    { click: this.hideClearAllModal.bind(this), buttonModel: { content: 'Cancel', isPrimary: false } }];
  public width = 'auto';
  public position = 'Right';
  brandFields = {text: 'brand_name', value: 'brand_code'};
  defaultTabFields = {text: 'common_name', value: 'common_code'};
  public visibilityFields: object = {value: 'visibility_code', text: 'visibility_name'};

  public formSent: boolean;
  referenceID = '';
  error$: Observable<any>;
  success$: Observable<string>;
  defaultTabDefaultValue = 'auto_select';
  preLoadedFiles: any;

  markerLat: number;
  markerLng: number;
  showUploadingBtn = false;

  showUploader$ = new BehaviorSubject(false);

  displayPasswordInput = false;

  public selectedItem:string = "public";

  public visibilityList: any[] = [
    {visibility_code: 'private', visibility_name: 'Private'},
    {visibility_code: 'password_protected', visibility_name: 'Password Protected'},
    {visibility_code: 'public', visibility_name: 'Public'},
  ];

  public setCleared$: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(false);
  companyLTDForm: FormGroup;
  // brandForm: FormGroup;
  _deliverableSubscription: any;

  public _companyLTDSettingsInput$: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null);
  
  @Input() set companyLTDSettingsInput(mode: any | null) {
    this._companyLTDSettingsInput$.next(mode);
  }

  get companyLTDSettings(): any | null {
    return this._companyLTDSettingsInput$.getValue();
  }

  constructor(private store: Store<AppState>, private commonService: CommonService,private brandService: BrandService) {
  }

  ngOnInit(): void {
    this.generateForm();

    this.success$ = this.store.pipe(select(store => store.companies.success.isForm));
    this.success$.subscribe((data: any) => {

      if (data) {
        this.formSent = false;
        this.store.dispatch(new CompanySetStatusNull());
        this.closeSidebar();
      }
    });
  }

  openSidebar(): void {
    this.sidebarInstance.show();
    if (this.type === 'images') {
      this.showUploader$.next(true);
    } else if (this.type === 'ltd-settings') {
      this.companyLTDForm.controls.defaultTab.setValidators(Validators.required);
      this.companyLTDForm.controls.defaultTab.markAsTouched();
      this.companyLTDForm.controls.defaultTab.updateValueAndValidity();

    }
  }

  closeSidebar(): void {
    this.formSent = false;
    this.showUploader$.next(false);
    if (this.sidebarInstance.isOpen) {
      this.clearForm(this.companyLTDForm);
    }
    this.sidebarInstance.hide();

    this.companyLTDForm.controls.defaultTab.clearValidators();
    this.companyLTDForm.controls.defaultTab.updateValueAndValidity();

    this.valueChanged.emit('success');
    this.preLoadedFiles = [];

    this.markerLat = 0;
    this.markerLng = 0;

  }

  generateForm() {
    this.companyLTDForm = new FormGroup({
      gps_latitude: new FormControl('', Validators.pattern('^-?([0]{1}\\.{1}[0-9]+|[1-9]{1}[0-9]*\\.{1}[0-9]+|[0-9]+|0)$')),
      gps_address: new FormControl(''),
      gps_longitude: new FormControl('', Validators.pattern('^-?([0]{1}\\.{1}[0-9]+|[1-9]{1}[0-9]*\\.{1}[0-9]+|[0-9]+|0)$')),
      gps_active: new FormControl(false),
      company_form_address: new FormControl(''),
      hide_socials_tab: new FormControl(false),
      is_premium_company: new FormControl(false),
      brands: new FormControl(''),
      defaultTab: new FormControl(''),
      visibility: new FormControl('', Validators.required),
      password_protected_password: new FormControl('', Validators.maxLength(255)),
    });

    // this.checkPremiumCompany();
  }

  // checkPremiumCompany() {
  //   this.store.dispatch(new CompanyOrderDeliverablesFilter({company_code: this.selectedCompanyCode}));
  //
  //   const deliverableSubVar = this.store.select(store => store.companies.deliverables);
  //   this._deliverableSubscription = deliverableSubVar.subscribe((data) => {
  //     if (data && data.result) {
  //       for (const deliverable of data.result) {
  //         if (deliverable.cost_center && deliverable.cost_center.cost_center_code === 'ltd') {
  //           this.companyLTDForm.controls.is_premium_company.setValue(true);
  //           break;
  //         }
  //       }
  //     }
  //   });
  // }

  ngOnDestroy(): void {
    this.showUploader$.complete();
    // this._deliverableSubscription.complete();
  }

  onUpdateCompanyLTD(form: FormGroup) {
    
    
    if (!form.invalid) {
      const formValues = form.getRawValue();

      this.store.dispatch(new CompanyUpdateLTDSettings({
        default_tab: formValues.defaultTab,
        company_code: this.selectedCompanyCode,
        address: formValues.gps_address,
        gps_latitude: formValues.gps_latitude,
        gps_longitude: formValues.gps_longitude,
        is_gps_active_yn: formValues.gps_active === false || formValues.gps_active === null ? 0 : 1,
        is_premium_company_yn: formValues.is_premium_company === false || formValues.is_premium_company === null ? 0 : 1,
        is_hide_socials_yn: formValues.hide_socials_tab === false || formValues.hide_socials_tab === null ? 0 : 1,
        request_form_email_address: _.join(formValues.company_form_address, ' , '),
        brands: _.cloneDeep(formValues.brands),
        featured_image_reference_id: this.referenceID,
        visibility: formValues.visibility,
        password_protected_password: formValues.password_protected_password
      }));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this._companyLTDSettingsInput$.getValue()) {
      const brandsArray = [];
      if (this._companyLTDSettingsInput$.getValue().brand) {
        for (const brand of this._companyLTDSettingsInput$.getValue().brand) {
          brandsArray.push(brand.brand_code);
        }
      }


      if (this.companyLTDForm) {
        this.companyLTDForm.setValue({
          visibility: this._companyLTDSettingsInput$.getValue().visibility ? this._companyLTDSettingsInput$.getValue().visibility : null,
          password_protected_password: this._companyLTDSettingsInput$.getValue().password_protected_password ? this._companyLTDSettingsInput$.getValue().password_protected_password : null,
          gps_address: this._companyLTDSettingsInput$.getValue().address ? this._companyLTDSettingsInput$.getValue().address : null,
          gps_latitude: this._companyLTDSettingsInput$.getValue().company_ltd_settings ? this._companyLTDSettingsInput$.getValue().company_ltd_settings[0].gps_latitude : null,
          gps_longitude: this._companyLTDSettingsInput$.getValue().company_ltd_settings ? this._companyLTDSettingsInput$.getValue().company_ltd_settings[0].gps_longitude : null,
          gps_active: this._companyLTDSettingsInput$.getValue().company_ltd_settings ? this._companyLTDSettingsInput$.getValue().company_ltd_settings[0].is_gps_active_yn === 1 : null,
          company_form_address: (this._companyLTDSettingsInput$.getValue().company_ltd_settings && this._companyLTDSettingsInput$.getValue().company_ltd_settings[0].request_form_email_address !== null) ? this._companyLTDSettingsInput$.getValue().company_ltd_settings[0].request_form_email_address.split(' , ') : null,
          hide_socials_tab: this._companyLTDSettingsInput$.getValue().company_ltd_settings ? this._companyLTDSettingsInput$.getValue().company_ltd_settings[0].is_hide_socials_yn === 1 : null,
          is_premium_company: this._companyLTDSettingsInput$.getValue().company_ltd_settings ? this._companyLTDSettingsInput$.getValue().company_ltd_settings[0].is_premium_company_yn === 1 : null,
          brands: brandsArray ? brandsArray : null,
          defaultTab: (this._companyLTDSettingsInput$.getValue().company_ltd_settings && (this._companyLTDSettingsInput$.getValue().company_ltd_settings[0].default_tab !== null || this._companyLTDSettingsInput$.getValue().company_ltd_settings[0].default_tab === '')) ? this._companyLTDSettingsInput$.getValue().company_ltd_settings[0].default_tab : this.defaultTabDefaultValue
        });

        if (this._companyLTDSettingsInput$.getValue().company_ltd_settings) {
          if (this._companyLTDSettingsInput$.getValue().company_ltd_settings[0].gps_latitude && this._companyLTDSettingsInput$.getValue().company_ltd_settings[0].gps_longitude) {
            this.markerLat = +this._companyLTDSettingsInput$.getValue().company_ltd_settings[0].gps_latitude;
            this.markerLng = +this._companyLTDSettingsInput$.getValue().company_ltd_settings[0].gps_longitude;
           
          }
        }

        if (this._companyLTDSettingsInput$.getValue().company_ltd_settings && this._companyLTDSettingsInput$.getValue().company_ltd_settings.length > 0
          && this._companyLTDSettingsInput$.getValue().company_ltd_settings[0].featured_image_reference_id) {
          this.referenceID = this._companyLTDSettingsInput$.getValue().company_ltd_settings[0].featured_image_reference_id;
          this.preLoadedFiles = [{
            filename: this._companyLTDSettingsInput$.getValue().company_ltd_settings[0].featured_image_filename,
            url: this._companyLTDSettingsInput$.getValue().company_ltd_settings[0].featured_image_reference_id
          }];
        }
       

        if (this.companyLTDForm.controls.defaultTab.value === null || this.companyLTDForm.controls.defaultTab.value === '') {
          this.companyLTDForm.controls.defaultTab.setValue([this.defaultTabDefaultValue]);
        }
      }

    }
  }

  updateReferenceID(args) {
    
    if (args.reference_id && args.isUploading == false) {
      this.referenceID = args.reference_id;
      this.formSent = false;
      this.showUploadingBtn = false;
    } else if (args.cleared === true) {
      // this.setCleared$.next(null);
      this.referenceID = null;
      this.formSent = false;
    } else if (args.unsupportedFileType === true) {
      this.setCleared$.next(null);
      // this.formSent = true;
    } else if (args.unsupportedFileType === false) {
      this.formSent = false;
    } else if (args.reference_id === '' && args.isUploading === false) {
      this.referenceID = args.reference_id;
      this.formSent = false;
      this.showUploadingBtn = false;
    } else if (args.isUploading === true) {
      this.formSent = true;
      this.showUploadingBtn = true;
     
    }
  }

  clearForm(form?) {
    
    if (this.type === 'brand') {
      this.companyLTDForm.controls.brands.setValue('');
    } else if (this.type === 'ltd-settings') {
      this.companyLTDForm.patchValue({
        gps_address: null,
        gps_latitude: null,
        gps_longitude: null,
        gps_active: false,
        company_form_address: null,
        hide_socials_tab: false,
        is_premium_company: false,
        brands: form.value.brands,
        defaultTab: null
      });
    } else if (this.type === 'images') {
      this.setCleared$.next(true);
    }
  }

  getCoordinates(form: FormGroup) {
    if (form.controls.gps_address.value !== null) {
      this.commonService.getGoogleMapsCoordinates(form.controls.gps_address.value).subscribe((data) => {
        if (data && data.data) {
          if (data.data.results && data.data.results.length > 0) {
            form.controls.gps_latitude.setValue(data.data.results[0].geometry.location.lat);
            form.controls.gps_longitude.setValue(data.data.results[0].geometry.location.lng);
            this.markerLng = data.data.results[0].geometry.location.lng;
            this.markerLat = data.data.results[0].geometry.location.lat;      
            this.latitudeInput.element.focus();     
            this.latitudeInput.element.blur();
          }
        }
      });
    }
  }

  onLatitudeChange(): void {
    const lat = this.companyLTDForm.get('gps_latitude')?.value;
    this.markerLat = +lat || 0;
  }
  onLongitudeChange(): void {   
    const lng = this.companyLTDForm.get('gps_longitude')?.value;
    this.markerLng = +lng || 0;
  }

  getAddress(form: FormGroup) {
    if (form.controls.gps_latitude.value !== null && form.controls.gps_longitude.value !== null) {
      this.commonService.getGoogleMapsAddress(form.controls.gps_latitude.value, form.controls.gps_longitude.value).subscribe((data) => {
        if (data.data) {
          if (data.data.results && data.data.results.length > 0) {
            form.controls.gps_address.setValue(data.data.results[0].formatted_address);
            this.latitudeInput.element.focus();     
            this.latitudeInput.element.blur();
          }
        }
      });
    }
  }

  changePasswordValidation(form: FormGroup, args) {
    if (form && args && args.itemData) {
      form.controls.visibility.setValue(args.itemData.visibility_code);
      if (form.controls.visibility.value === 'password_protected') {
        form.controls.password_protected_password.setValidators([Validators.required, Validators.maxLength(255)]);
        form.updateValueAndValidity();
        this.displayPasswordInput = true;
      } else {
        this.displayPasswordInput = false;
        form.controls.password_protected_password.clearValidators();
        form.updateValueAndValidity();
  
      }
    }
  }

  setDefaultValue () {
    if (!this._companyLTDSettingsInput$.getValue()) {
      if(this.visibilityDropdown?.value){
        this.visibilityDropdown.value = this.selectedItem;
      }
    }
  }
 
  create(args){
    
    


 document.addEventListener('click', e => {
  const tgt = e.target as HTMLElement;
  const inline = tgt.style.cursor || "Not defined"
  const computed = window.getComputedStyle(tgt)["cursor"]
  
  if(computed=='default'){
    
    this.companyLTDForm.patchValue({
    
      company_form_address: null,
     
    });
  }
 });;

}

setDebounce = debounce((e, entity: string) => {
  this.onFilter(e, entity);
}, 700);

public onFiltering = (e, entity: string) => {
  e.preventDefaultAction = true;
  this.setDebounce(e, entity);
};

public onFilter: EmitType<any> = (e: FilteringEventArgs, entity: string) => {
  let query = new Query();
  const brandList = [];

  query = (e.text !== '') ? query.where('brand_name', 'contains', e.text, true) : query;
  const filterParams = {search: e.text, page_size: 40, page_no: 1};

  this.brandService.getSearchResults(filterParams).subscribe((data) => {
    if (data && data.data) {
      if (data.data.result) {
        for (const brand of data.data.result) {
          // const checkPub = brand.publish_status !== 'publish' ? ' [NP]' : ' [P]';
          brandList.push({...brand, brand_name: brand.brand_name});
        }
        // this.brandList = _.clone(brandList);
        e.updateData(_.clone(brandList), query);
      }
    }
  });
};

clearAll(){
  this.clearModal.hide()
this.clearForm(this.companyLTDForm)
if (this.type === 'images') {
  this.setCleared$.next(true);
}

}
hideClearAllModal(){
  this.clearModal.hide()
}
clear(){
  this.clearModal.show()
}

}
