<ejs-sidebar id="sidebar-menu" #sidebarInstance type='Over' [width]='width' [position]='position'
             style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true>

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
       (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>

  <div class='p-15'>
    <h2 *ngIf="!contactInput">Add Contact</h2>
    <h2 *ngIf="contactInput">Update Contact</h2>

    <form id="contactForm" [formGroup]="contactForm" #formDir="ngForm" class="form-horizontal" novalidate="" style="margin-top: 16px;">

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="firstName" placeholder="First Name *" cssClass="e-filled"
                     formControlName="firstName" floatLabelType="Auto"
                     (blur)="contactForm.controls.firstName.markAsTouched(); getContactSlug(contactForm.value.firstName, contactForm.value.lastName, contactForm)">
        </ejs-textbox>
        <div *ngIf="contactForm.controls.firstName.errors">
          <div *ngIf="contactForm.controls.firstName.errors.required && contactForm.controls.firstName.touched" class="e-error">
            First Name is required.
          </div>
          <div *ngIf="contactForm.controls.firstName.errors.invalidText && !contactForm.controls.firstName.errors.required" class="e-error">
            Please enter a valid first name
          </div>
        </div>
      </div>


      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="lastName" placeholder="Last Name *" cssClass="e-filled"
                     formControlName="lastName" floatLabelType="Auto"
                     (blur)="contactForm.controls.lastName.markAsTouched(); getContactSlug(contactForm.value.firstName, contactForm.value.lastName, contactForm)">
        </ejs-textbox>
        <div *ngIf="contactForm.controls.lastName.errors">
          <div *ngIf="contactForm.controls.lastName.errors.required && contactForm.controls.lastName.touched" class="e-error">
            Last Name is required.
          </div>
          <div *ngIf="contactForm.controls.lastName.errors.invalidText && !contactForm.controls.lastName.errors.required " class="e-error">
            Please enter a valid last name
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="slug" placeholder="Slug *" cssClass="e-filled"
                     formControlName="slug" floatLabelType="Auto"
                     (blur)="contactForm.controls.slug.markAsTouched()"
        ></ejs-textbox>
        <div *ngIf="contactForm.controls.slug.errors">
          <div *ngIf="(contactForm.controls.slug.errors.required && contactForm.controls.slug.touched)"
               class="e-error">
            Slug is required
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="excerpt" [multiline]="true" placeholder="Excerpt" cssClass="e-filled"
                     formControlName="excerpt" floatLabelType="Auto"
                     (blur)="contactForm.controls.excerpt.markAsTouched()"
        ></ejs-textbox>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="email" placeholder="Email *" cssClass="e-filled"
                     formControlName="email" floatLabelType="Auto"
                     (blur)="contactForm.controls.email.markAsTouched()"
        ></ejs-textbox>
        <div *ngIf="contactForm.controls.email.errors">
          <div *ngIf="(contactForm.controls.email.errors.required && contactForm.controls.email.touched)"
               class="e-error">
            Email is required
          </div>
          <div *ngIf="( contactForm.controls.email.errors.invalidEmail)"
               class="e-error">
            Please enter a valid email
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10" *ngIf="!isCompanyLink()">
        <ejs-dropdownlist showClearButton="true" #company id='companyDropdown' formControlName="company" floatLabelType="Auto" cssClass="e-filled"
                          [dataSource]='companyList' [fields]='companyFields' placeholder='Company'
                          [allowFiltering]='true' (close)="page_no = 0" (change)="setCompanyValue($event, contactForm)"
                          (filtering)='onFiltering($event, "company")'  (open)="open()">
        </ejs-dropdownlist>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="designation" placeholder="Designation" cssClass="e-filled"
                     formControlName="designation" floatLabelType="Auto" (blur)="contactForm.controls.designation.markAsTouched()"
        ></ejs-textbox>
        <div *ngIf="contactForm.controls.designation.errors">
          <div *ngIf="contactForm.controls.designation.errors.maxlength && contactForm.controls.designation.touched"
               class="e-error">
            Designation cannot be more than 100 characters
          </div>
        </div>
      </div>
      <!-- <div class="form-group sf-outline-input mb-10">
        <ejs-numerictextbox format='###.##'  value='currentRankOrder'  id="rankorder" placeholder="Rank Order" cssClass="e-filled"
                     formControlName="rankorder" floatLabelType="Auto" (blur)="contactForm.controls.designation.markAsTouched()"
        ></ejs-numerictextbox>
        <div *ngIf="contactForm.controls.rankorder.errors">
          <div *ngIf="(contactForm.controls.rankorder.errors.required && contactForm.controls.rankorder.touched)"
               class="e-error">
            please fill the rank order
          </div>
        </div>
      </div> -->

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="website" placeholder="Website" cssClass="e-filled"
                     formControlName="website" floatLabelType="Auto"
                     (blur)="contactForm.controls.website.markAsTouched()"
        ></ejs-textbox>
        <div *ngIf="contactForm.controls.website.errors">
          <div *ngIf="contactForm.controls.website.errors.invalidurl && contactForm.controls.website.touched "
               class="e-error">
            Please enter a valid url.
          </div>
          <div *ngIf="contactForm.controls.website.errors.maxlength && contactForm.controls.website.touched"
               class="e-error">
            Website cannot be more than 200 characters
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-datepicker formControlName="start_date" openOnFocus="true" [allowEdit]=false
                        (blur)="contactForm.controls.start_date.markAsTouched()"
                        placeholder='Start Date *' floatLabelType='Auto' cssClass="e-filled">
        </ejs-datepicker>
        <div *ngIf="contactForm.controls.start_date.errors">
          <div *ngIf="contactForm.controls.start_date.errors.required && contactForm.controls.start_date.touched "
               class="e-error">
            Start Date is required
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-datepicker formControlName="end_date" openOnFocus="true" [allowEdit]=false [min]="contactForm.value.start_date"
                        (blur)="contactForm.controls.end_date.markAsTouched()"
                        placeholder='End Date' floatLabelType='Auto' cssClass="e-filled">
        </ejs-datepicker>
        <div *ngIf="contactForm.errors">
          <div *ngIf="contactForm.errors.fromToDate"
               class="e-error">
            End date cannot be less than start date
          </div>
        </div>
      </div>

      <!-- <div class="form-group sf-outline-input mb-10" >
        <div>
          <ejs-dropdownlist id='visibilityDropdown' #visibility formControlName="visibility" floatLabelType="Auto" cssClass="e-filled" [value]="selectedItem"
                            [dataSource]='visibilityList' [fields]='visibilityFields' placeholder='Visibility *' (created)="setDefaultValue()"
                            (change)="changePasswordValidation(contactForm, $event)">
          </ejs-dropdownlist>
          <div *ngIf="contactForm.controls.visibility.errors">
            <div *ngIf="contactForm.controls.visibility.errors.required && contactForm.controls.visibility.touched" class="e-error">
              Visibility is required.
            </div>
          </div>
        </div>
        
        <div *ngIf="displayPasswordInput" class="mt-4">
          <ejs-textbox id="lastName" placeholder="Password *" cssClass="e-filled"
                      formControlName="password_protected_password" floatLabelType="Auto"
                      (blur)="contactForm.controls.password_protected_password.markAsTouched()">
          </ejs-textbox>
          <div *ngIf="contactForm.controls.password_protected_password.errors">
            <div *ngIf="contactForm.controls.password_protected_password.errors.required && contactForm.controls.password_protected_password.touched" class="e-error">
              Password is required.
            </div>
            <div *ngIf="contactForm.controls.password_protected_password.errors.maxlength && contactForm.controls.password_protected_password.touched"
               class="e-error">
            Password cannot be more than 255 characters
          </div>
          </div>
        </div>

      </div> -->

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="leadSource" placeholder="Lead Source" cssClass="e-filled"
                     formControlName="leadSource" floatLabelType="Auto" (blur)="contactForm.controls.leadSource.markAsTouched()"
        ></ejs-textbox>
        <div *ngIf="contactForm.controls.leadSource.errors">
          <div *ngIf="contactForm.controls.leadSource.errors.maxlength && contactForm.controls.leadSource.touched"
               class="e-error">
            Lead Source cannot be more than 100 characters
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="contactNumber" placeholder="Contact Number" cssClass="e-filled" (keyup)="onInputChange($event)" (paste)="onPaste($event)"
                     formControlName="contactNumber" floatLabelType="Auto" (blur)="contactForm.controls.contactNumber.markAsTouched()"
        ></ejs-textbox>
        <div *ngIf="contactForm.controls.contactNumber.errors">
          <div *ngIf="contactForm.controls.contactNumber.touched && contactForm.controls.contactNumber.errors.invalidNumber"
            class="e-error">
              Please enter a valid contact number
           </div>
          <div *ngIf="contactForm.controls.contactNumber.errors.maxlength && contactForm.controls.contactNumber.touched"
               class="e-error">
            Contact Number cannot be more than 50 digits
          </div>
        </div>
      </div>

<!--      <div class="form-group sf-outline-input mb-10">-->
<!--        <ejs-multiselect id='tags' #tags [dataSource]="tagModelData" floatLabelType="Auto" cssClass="e-filled"-->
<!--                         [fields]="tagFields" formControlName='tags'-->
<!--                         [allowCustomValue]=true mode='Box' placeholder='Tags'-->
<!--                         (actionComplete)="actionComplete($event)"-->
<!--                         (ngModelChange)="syncModel($event)"-->
<!--                         (change)="onChange($event)"-->
<!--                         (blur)="onBlur($event, contactForm)"-->
<!--                         name="tags"-->
<!--                         (removed)="selectedTag = []"-->
<!--                         ([ngModel])="modelData"-->
<!--                         (select)="select($event)"-->
<!--                         ngModel></ejs-multiselect>-->
<!--      </div>-->


      <div *ngIf="contactForm.invalid" class="e-error">Please fill all fields</div>
<!--       <div *ngIf="errorMsg">{{errorMsg}}</div>-->
      <div *ngIf="error$ | async as error" class="e-error"> <span *ngIf="!contactForm.invalid">{{error.error}}</span></div>
<!--      <div *ngIf="error$ | async as error" class="e-error"> <span>{{error.error}}</span> {{error.error}}</div>-->


      <!-- Action Buttons -->

      <div class="my-10 pb-20">
        <button id="resetbtn"
                class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
                type="button"
                data-ripple="true" (click)="clear()">Clear
        </button>
        <button *ngIf="!contactInput" id="add"
                class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
                (click)="onAddContact(contactForm)" [disabled]="formSent" data-ripple="true">Add Contact
        </button>
        <button *ngIf="contactInput" id="update"
                class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
                (click)="onUpdateContact(contactForm)" [disabled]="formSent" data-ripple="true">Update Contact
        </button>
      </div>
    </form>
  </div>
</ejs-sidebar>



<ejs-dialog #clearModal [buttons]='dltButtons' header='Are you sure?' animationSettings='none' showCloseIcon='true'
            width='25%' [visible]='false'>
  <ng-template #content>
    <p>This action will clear all form fields.</p>
  </ng-template>
</ejs-dialog>
