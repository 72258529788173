<ejs-sidebar id="sidebar-menu" #sidebarInstance type='Over' [width]='width' [position]='position'
  style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true>

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
      (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>

  <div class='p-15'>
    <h2 *ngIf="!companyInput">Associate New Company</h2>
    <h2 *ngIf="companyInput">Update Associated Company</h2>

    <form id="associateContactForm" [formGroup]="associateCompanyForm" #formDir="ngForm" class="form-horizontal"
      novalidate="" style="margin-top: 16px;">

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="email" placeholder="Email *" cssClass="e-filled" formControlName="email" floatLabelType="Auto"
          (blur)="associateCompanyForm.controls.email.markAsTouched()"></ejs-textbox>
        <div *ngIf="associateCompanyForm.controls.email.errors">
          <div
            *ngIf="(associateCompanyForm.controls.email.errors.required && associateCompanyForm.controls.email.touched)"
            class="e-error">
            Email is required
          </div>
          <div *ngIf="( associateCompanyForm.controls.email.errors.invalidEmail)" class="e-error">
            Please enter a valid email
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <!--        <ejs-dropdownlist #company id='companyDropdown' formControlName="company"-->
        <!--                          floatLabelType="Auto" cssClass="e-filled"-->
        <!--                          [dataSource]='companyList' [fields]='companyFields' placeholder='Company' [showClearButton]=true-->
        <!--                          [allowFiltering]='true' (change)="setCompanyValue($event)"-->
        <!--                          (filtering)='onFiltering($event, "company")' (focus)="onFocus()"-->
        <!--                          (blur)="associateCompanyForm.controls.company.markAsTouched()"></ejs-dropdownlist>-->
        <ejs-dropdownlist #company formControlName='company' id='companyList' [dataSource]='companyList'
          placeholder='Company' [fields]='companyFields' cssClass="e-filled" floatLabelType="Auto"
          [showClearButton]=true [allowFiltering]='true' (filtering)="onFiltering($event, 'company')"
          (blur)="associateCompanyForm.controls.company.markAsTouched()"></ejs-dropdownlist>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="designation" placeholder="Designation" cssClass="e-filled" formControlName="designation"
          floatLabelType="Auto" (blur)="associateCompanyForm.controls.designation.markAsTouched()"></ejs-textbox>
        <div *ngIf="associateCompanyForm.controls.designation.errors">
          <div
            *ngIf="associateCompanyForm.controls.designation.errors.maxlength && associateCompanyForm.controls.designation.touched"
            class="e-error">
            Designation cannot be more than 100 characters
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-textbox id="contactNumber" placeholder="Contact Number" cssClass="e-filled"
          formControlName="contactNumber" floatLabelType="Auto" (keyup)="onInputChange($event)"
          (paste)="onPaste($event)"
          (blur)="associateCompanyForm.controls.contactNumber.markAsTouched()"></ejs-textbox>
        <div *ngIf="associateCompanyForm.controls.contactNumber.errors">
          <div
            *ngIf="associateCompanyForm.controls.contactNumber.touched && associateCompanyForm.controls.contactNumber.errors.invalidNumber"
            class="e-error">
            Please enter a valid contact number
          </div>
          <div
            *ngIf="associateCompanyForm.controls.contactNumber.errors.maxlength && associateCompanyForm.controls.contactNumber.touched"
            class="e-error">
            Contact Number cannot be more than 50 digits
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-datepicker formControlName="start_date" openOnFocus="true" [allowEdit]=false placeholder='Start Date *'
          floatLabelType='Auto' cssClass="e-filled">
        </ejs-datepicker>
        <div *ngIf="associateCompanyForm.controls.start_date.errors">
          <div
            *ngIf="associateCompanyForm.controls.start_date.errors.required && associateCompanyForm.controls.start_date.touched "
            class="e-error">
            Start Date is required
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-datepicker formControlName="end_date" openOnFocus="true" [allowEdit]=false
          [min]="associateCompanyForm.value.start_date" (blur)="associateCompanyForm.controls.end_date.markAsTouched()"
          placeholder='End Date' floatLabelType='Auto' cssClass="e-filled">
        </ejs-datepicker>
        <div *ngIf="associateCompanyForm.errors">
          <div *ngIf="associateCompanyForm.errors.fromToDate" class="e-error">
            End date cannot be less than start date
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-checkbox formControlName="is_primary_yn" #wcheckbox label="Active Company: "
          labelPosition="Before"></ejs-checkbox>
        <!--        <div class="e-error mt-3" *ngIf="!disabledState">Warning: Previously Selected Active company will be replaced with this one</div>-->
      </div>

      <div class="form-group sf-outline-input mb-10">
        <ejs-checkbox formControlName="is_display_yn" #wcheckbox label="Display Company: " labelPosition="Before"
          [disabled]="disabledState"></ejs-checkbox>
        <div class="e-error mt-3" *ngIf="!disabledState">Warning: Previously Selected display company will be replaced
          with this one</div>
      </div>


      <div *ngIf="!associateCompanyForm.value.company" class="e-error">Warning: No Company Chosen. New record will be
        considered as a freelancer</div>
      <div *ngIf="associateCompanyForm.invalid" class="e-error mt-1">Please fill all fields</div>
      <!--       <div *ngIf="errorMsg">{{errorMsg}}</div>-->
      <div *ngIf="error$ | async as error" class="e-error"> <span
          *ngIf="!associateCompanyForm.invalid">{{error.error}}</span></div>
      <!--      <div *ngIf="error$ | async as error" class="e-error"> <span>{{error.error}}</span> {{error.error}}</div>-->


      <!-- Action Buttons -->

      <div class="my-10 pb-20">
        <button id="resetbtn"
          class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
          type="button" data-ripple="true" (click)="clear()">Clear
        </button>
        <button id="add" *ngIf="!companyInput"
          class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
          (click)="addCompany(associateCompanyForm)" [disabled]="formSent" data-ripple="true">Associate Company
        </button>
        <button *ngIf="companyInput" id="update"
          class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
          (click)="updateCompany(associateCompanyForm)" [disabled]="formSent" data-ripple="true">Update Associated
          Company
        </button>
      </div>
    </form>
  </div>
</ejs-sidebar>

<ejs-dialog #clearModal [buttons]='dltButtons' header='Are you sure?' animationSettings='none' showCloseIcon='true'
            width='25%' [visible]='false'>
  <ng-template #content>
    <p>This action will clear all form fields.</p>
  </ng-template>
</ejs-dialog>