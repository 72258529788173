import {
  Component,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { SidebarComponent } from "@syncfusion/ej2-angular-navigations";
import {
  FilteringEventArgs,
  MultiSelectComponent,
} from "@syncfusion/ej2-angular-dropdowns";
import { Observable } from "rxjs";
import { Tags } from "../../../../../core/models/tags.model";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { AppState } from "../../../../../core";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import {
  ContactFilter,
  ContactLoad,
  ContactSetStatusNull,
  GetSearchedContact,
} from "../../../../../core/store/actions/contact.actions";
import * as _ from "lodash";
import {
  ProjectSourceFilter,
  ProjectSourceLoad,
} from "../../../../../core/store/actions/project-source.actions";
import { debounce, EmitType } from "@syncfusion/ej2-base";
import { Query } from "@syncfusion/ej2-data";
import { CompanyService } from "../../../../../core/store/services/company.service";
import { ContactService } from "../../../../../core/store/services/contact.service";
import { ProjectLoad } from "src/app/core/store/actions/project.actions";
import {
  ArticleFilter,
  ArticleLoad,
} from "../../../../../core/store/actions/article.actions";
import {
  CompanyFilter,
  CompanyLoad,
  GetSearchedCompany,
} from "../../../../../core/store/actions/company.actions";
import {
  LoadArticleDate,
  LoadDeliverableItemDate,
} from "../../../../../core/store/actions/common.actions";
import {
  ButtonPropsModel,
  DialogComponent,
} from "@syncfusion/ej2-angular-popups";

@Component({
  selector: "app-project-source-filter",
  templateUrl: "./project-source-filter.component.html",
  styleUrls: ["./project-source-filter.component.scss"],
})
export class ProjectSourceFilterComponent implements OnInit, OnChanges {
  @ViewChild("sidebarInstance")
  public sidebarInstance: SidebarComponent;
  @ViewChild("contact_tags") contactTag: MultiSelectComponent;
  @ViewChild("company_tags") companyTag: MultiSelectComponent;
  @ViewChild("project_tags") projectTag: MultiSelectComponent;
  @ViewChild("articleContactCode") articleContactDropdown: MultiSelectComponent;
  @ViewChild("articleCompanyCode") articleCompanyCode: MultiSelectComponent;
  @ViewChild("owner") owner: MultiSelectComponent;
  @ViewChild("clearModal") public clearModal: DialogComponent;

  @Input() countries: any;
  @Input() companyTypes: any;
  @Input() communityTypes: any;
  @Input() users: any;
  @Input() sourceTypes: any;
  @Input() projectSourceStatus: any;
  @Input() articleCategory: any;
  @Input() companyRoles: any;
  @Input() allTags: Observable<Array<Tags>>;
  @Input() projectTypes: any;
  @Input() projectStatus: any;
  @Input() regions: any;
  @Input() isPopulateSidebar: any;
  @Input() editions: any;
  @Input() articleType: any;

  @Input() companyList: any[];
  @Input() contactList: any[];

  contactListCopy = [];
  public dltButtons: ButtonPropsModel[] = [
    {
      click: this.clearAll.bind(this),
      buttonModel: { content: "Yes", isPrimary: true },
    },
    {
      click: this.hideClearAllModal.bind(this),
      buttonModel: { content: "Cancel", isPrimary: false },
    },
  ];
  public width = "auto";
  public position = "Right";
  selectedTag = [];
  tagSelected = "";
  public tagModelData = [];
  modelDataCompanyTag = [];
  modelDataContactTag = [];
  modelDataProjectTag = [];
  dateowner = [];
  i = 0;
  errorMsg: any;
  error$: Observable<any>;
  success$: Observable<any>;
  selectedTab = "source";
  selectedCompanyTypes = [];
  sourceTypeFields: any;
  currentDateOwnerField: any = [];
  currentDateOwnerValue: any = [];
  currentDateOwnerFieldArray = {};
  currentPage = "";
  queryParams: any;
  tentativeFields = { value: "tentative_value", text: "tentative" };

  status = [
    {
      status_value: "0",
      status: "Active",
    },
    {
      status_value: "1",
      status: "Inactive",
    },
  ];

  premium = [
    {
      value: "0",
      text: "Non Premium",
    },
    {
      value: "1",
      text: "Premium",
    },
  ];
  PulishedStatus = [
    {
      value: "0",
      text: "Not Published",
    },
    {
      value: "1",
      text: "Published",
    },
  ];
  tentativeOptions = [
    {
      tentative_value: "tentative",
      tentative: "Tentative",
    },
    {
      tentative_value: "not-tentative",
      tentative: "Not Tentative",
    },
  ];

  startWeek = 1;

  allowEdit = false;
  isFilterReset = false;
  formSent: boolean;
  public tagFields: object = { value: "tag", text: "tag" };
  articleCategoryFields: object = {
    value: "article_category_code",
    text: "article_category_name",
  };
  public userFields: object = {
    dataSource: this.users,
    value: "user_code",
    text: "first_name",
  };
  public countryField = { value: "country_code", text: "country_name" };
  public projectSourceStatusField = {
    value: "project_source_status_code",
    text: "project_source_status_name",
  };
  public companyFields: object = {
    value: "company_code",
    text: "company_name",
  };
  public contactFields: object = { value: "value", text: "name" };
  public statusFields: object = {
    dataSource: this.status,
    value: "status_value",
    text: "status",
  };
  public premiumFields: object = {
    dataSource: this.premium,
    value: "value",
    text: "text",
  };
  public PulishedFields: object = {
    dataSource: this.PulishedStatus,
    value: "value",
    text: "text",
  };
  public articleTypeFields: object = {
    dataSource: this.articleType,
    value: "article_type_code",
    text: "article_type_name",
  };
  public editionFields: object = {
    dataSource: this.editions,
    value: "edition_code",
    text: "edition_name",
  };

  public companyRoleField: object;
  public projectTypeFields: object;
  public projectStatusFields: object;
  public regionFields: object;
  // value: any[]
  value: any = "";
  articleDates: any[];
  public companyTypeField: object;
  public communityTypeField = {
    value: "community_type_code",
    text: "community_type_name",
  };

  public treeSettings: object = { autoCheck: true };

  filterForm: FormGroup;
  articleFilterForm: FormGroup;
  companyFilterForm: FormGroup;
  contactFilterForm: FormGroup;
  projectFilterForm: FormGroup;
  articleDateForm: FormGroup;
  selectedCode: any;
  setDebounce = debounce((e, entity: string) => {
    this.onFilter(e, entity);
  }, 700);

  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    event: KeyboardEvent,
  ) {
    if (this.sidebarInstance.isOpen) {
      this.closeSidebar();
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private router: Router,
    private companyService: CompanyService,
    private contactService: ContactService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.currentPage = this.checkType();
    this.generateFilterForm();
    this.allTags.subscribe((data) => {
      this.tagModelData = data;
    });

    if (this.currentPage === "article") {
      this.selectedTab = "contact";
    } else {
      this.selectedTab = "source";
    }
    this.route.parent.paramMap.subscribe((data: any) => {
      this.selectedCode = data.params.company_code;
    });
    if (this.currentPage === "contacts") {
      this.error$ = this.store.pipe(select((store) => store.contacts.error));
    } else if (this.currentPage === "project") {
      this.error$ = this.store.pipe(select((store) => store.projects.error));
    } else if (this.currentPage === "company") {
      this.error$ = this.store.pipe(select((store) => store.companies.error));
    } else {
      this.error$ = this.store.pipe(
        select((store) => store.projectSources.error),
      );
    }

    this.error$.subscribe((data: any) => {
      this.errorMsg = data ? data.error : null;

      if (this.errorMsg) {
        // this.errorMsg = data.error;
        this.formSent = false;
      }
    });

    if (this.currentPage === "contact") {
      this.success$ = this.store.pipe(
        select((store) => store.contacts.success),
      );
      this.success$.subscribe((data: any) => {
        if (data && data.isForm !== "") {
          this.formSent = false;

          if (!this.isFilterReset) {
            this.closeSidebar();
          }
          // this.store.dispatch(new ContactSetStatusNull());
        }
      });
    } else if (this.currentPage === "project") {
      this.success$ = this.store.pipe(
        select((store) => store.projects.success),
      );
      this.success$.subscribe((data: any) => {
        if (data) {
          this.formSent = false;
          if (!this.isFilterReset) {
            this.closeSidebar();
          }
          // this.store.dispatch(new ContactSetStatusNull());
        }
      });
    } else if (this.currentPage === "company") {
      this.success$ = this.store.pipe(
        select((store) => store.companies.success),
      );
      this.success$.subscribe((data: any) => {
        if (data && data.isForm !== "") {
          this.formSent = false;
          if (!this.isFilterReset) {
            this.closeSidebar();
          }
          // this.store.dispatch(new ContactSetStatusNull());
        }
      });
    } else if (this.currentPage === "article") {
      this.getArticleDates();
      this.success$ = this.store.pipe(
        select((store) => store.articles.success),
      );
      this.success$.subscribe((data: any) => {
        if (data) {
          this.formSent = false;
          if (!this.isFilterReset) {
            this.closeSidebar();
          }
          // this.store.dispatch(new ContactSetStatusNull());
        }
      });
    } else {
      this.success$ = this.store.pipe(
        select((store) => store.projectSources.success),
      );
      this.success$.subscribe((data: any) => {
        if (data) {
          this.formSent = false;
          if (!this.isFilterReset) {
            this.closeSidebar();
          }
          // this.store.dispatch(new ContactSetStatusNull());
        }
      });
    }
  }

  generateFilterForm() {
    this.articleFilterForm = this.formBuilder.group({
      article_category_code: new FormControl(""),
      article_name: new FormControl(null),
      article_reference_id: new FormControl(null),
      article_country_code: new FormControl(null),
      article_type_code: new FormControl(null),
      article_edition_code: new FormControl(null),
      article_community_type_code: new FormControl(""),
      article_created_from: new FormControl(null),
      article_created_till: new FormControl(null),
      article_updated_from: new FormControl(null),
      article_updated_till: new FormControl(null),
      article_contact_code: new FormControl(null),
      article_company_code: new FormControl(null),
      article_published_from: new FormControl(null),
      article_published_till: new FormControl(null),
      article_updated_by: new FormControl(null),
      article_created_by: new FormControl(null),
      article_published_by: new FormControl(null),
      article_assigned_to: new FormControl(null),
      article_is_published_yn: new FormControl(false),
    });

    this.contactFilterForm = this.formBuilder.group({
      contact_name: new FormControl(null),
      contact_company_name: new FormControl(null),
      contact_community_type_code: new FormControl(""),
      contact_country_code: new FormControl(null),
      contact_tag: new FormControl(null),
      contact_updated_at: new FormControl(null),
      contact_created_at: new FormControl(null),
      contact_published_by: new FormControl(null),
      contact_reference_id: new FormControl(null),
      contact_email: new FormControl(null),
      contact_is_published_yn: new FormControl(""),
    });

    this.companyFilterForm = this.formBuilder.group({
      company_name: new FormControl(null),
      company_community_type_code: new FormControl(""),
      company_account_owner: new FormControl(""),
      company_account_manager: new FormControl(""),
      company_country_code: new FormControl(null),
      company_tag: new FormControl(""),
      company_updated_at: new FormControl(null),
      company_created_at: new FormControl(null),
      company_published_by: new FormControl(null),
      company_reference_id: new FormControl(null),
      company_company_role_code: new FormControl(""),
      company_is_inactive_yn: new FormControl(""),
      company_is_premium_company_yn: new FormControl(""),
      company_is_published_yn: new FormControl(""),
      // company_is_published_yn: new FormControl(false)
    });

    this.filterForm = this.formBuilder.group({
      project_source_type_code: new FormControl(""),
      project_source_status_code: new FormControl(null),
      status_updated_at: new FormControl(null),
      project_source_updated_at: new FormControl(null),
      project_source_created_at: new FormControl(null),
      project_source_updated_by: new FormControl(null),
      project_source_created_by: new FormControl(null),
      project_source_reference_id: new FormControl(null),

      project_source_company_name: new FormControl(null),
      project_source_company_community_type_code: new FormControl(""),
      project_source_company_company_role_code: new FormControl(""),
      project_source_company_account_owner: new FormControl(""),
      project_source_company_account_manager: new FormControl(""),
      project_source_company_country_code: new FormControl(null),
      project_source_company_tag: new FormControl(""),
      project_source_company_updated_at: new FormControl(null),
      project_source_company_created_at: new FormControl(null),
      project_source_company_published_by: new FormControl(null),
      project_source_company_reference_id: new FormControl(null),
      project_source_company_is_inactive_yn: new FormControl(""),

      project_source_contact_name: new FormControl(null),
      project_source_contact_company_name: new FormControl(null),
      project_source_contact_community_type_code: new FormControl(""),
      project_source_contact_country_code: new FormControl(null),
      project_source_contact_tag: new FormControl(null),
      project_source_contact_updated_at: new FormControl(null),
      project_source_contact_created_at: new FormControl(null),
      project_source_contact_published_by: new FormControl(null),
      project_source_contact_reference_id: new FormControl(null),
      project_source_contact_email: new FormControl(null),

      project_source_project_name: new FormControl(null),
      project_source_project_reference_id: new FormControl(null),
      project_source_project_type_code: new FormControl(""),
      project_source_project_status_code: new FormControl(""),
      project_source_project_assigned_to_user: new FormControl(""),
      project_source_project_published_by: new FormControl(""),
      project_source_project_country_code: new FormControl(""),
      project_source_project_region_code: new FormControl(""),
      project_source_project_proposed_post_date: new FormControl(null),
      project_source_project_tag: new FormControl(""),
      project_source_project_created_at: new FormControl(null),
      project_source_project_updated_at: new FormControl(null),
      project_source_project_contact_code: new FormControl(null),
      project_source_project_company_code: new FormControl(null),
      project_source_project_updated_by: new FormControl(null),
      project_source_project_created_by: new FormControl(null),
      project_source_project_featured_date: new FormControl(null),
      project_source_project_is_deleted_yn: new FormControl(null),
      project_source_project_is_source_exist: new FormControl(null),
    });

    this.projectFilterForm = this.formBuilder.group({
      project_name: new FormControl(null),
      project_reference_id: new FormControl(null),
      project_type_code: new FormControl(""),
      project_status_code: new FormControl(""),
      project_assigned_to_user: new FormControl(""),
      project_published_by: new FormControl(""),
      project_country_code: new FormControl(""),
      project_proposed_post_date: new FormControl(null),
      project_tag: new FormControl(""),
      project_created_at: new FormControl(null),
      project_updated_at: new FormControl(null),
      project_contact_code: new FormControl(null),
      project_company_code: new FormControl(null),
      project_updated_by: new FormControl(null),
      project_created_by: new FormControl(null),
      project_featured_date: new FormControl(null),
      project_is_deleted_yn: new FormControl(null),
      project_is_source_exist: new FormControl(null),
      project_project_region_code: new FormControl(""),
      project_is_published_yn: new FormControl(false),

      project_role_company_name: new FormControl(null),
      project_role_company_community_type_code: new FormControl(""),
      project_role_company_company_role_code: new FormControl(""),
      project_role_company_account_owner: new FormControl(""),
      project_role_company_country_code: new FormControl(null),
      project_role_company_tag: new FormControl(""),
      project_role_company_updated_at: new FormControl(null),
      project_role_company_created_at: new FormControl(null),
      project_role_company_published_by: new FormControl(null),
      project_role_company_reference_id: new FormControl(null),
      project_role_company_is_inactive_yn: new FormControl(""),

      project_role_contact_name: new FormControl(null),
      project_role_contact_company_name: new FormControl(null),
      project_role_contact_community_type_code: new FormControl(""),
      project_role_contact_country_code: new FormControl(null),
      project_role_contact_tag: new FormControl(null),
      project_role_contact_updated_at: new FormControl(null),
      project_role_contact_created_at: new FormControl(null),
      project_role_contact_published_by: new FormControl(null),
      project_role_contact_reference_id: new FormControl(null),
      project_role_contact_email: new FormControl(null),
    });

    this.articleDateForm = this.formBuilder.group({});

    this.route.queryParams.subscribe((res) => {
      if (Object.keys(res).length !== 0) {
        this.queryParams = res;
        this.populateForm(this.queryParams);
      }
    });
  }

  addDateToFilter(date) {
    const articleDateFound = this.articleDates.find(
      (item) => item.article_date_code === date,
    );
    if (articleDateFound) {
      this.articleDateForm.addControl(
        "is_tentative_" +
          articleDateFound.article_date_code.toString().replace(/-/g, "_"),
        new FormControl(false),
      );
      this.articleDateForm.addControl(
        articleDateFound.article_date_code.toString().replace(/-/g, "_") +
          "_to",
        new FormControl(false),
      );
      this.articleDateForm.addControl(
        articleDateFound.article_date_code.toString().replace(/-/g, "_") +
          "_from",
        new FormControl(true),
      );
      this.articleDateForm.addControl(
        articleDateFound.article_date_code.toString().replace(/-/g, "_") +
          "_date_owner",
        new FormControl(true),
      );
      this.articleDateForm.addControl(
        "is_completed_" +
          articleDateFound.article_date_code.toString().replace(/-/g, "_"),
        new FormControl(false),
      );

      // if (this.deliverableItemDateForm.controls['is_tentative_' + deliverableFound.deliverable_item_date_code.toString().replace(/-/g, '_')].value === false) {
      //   this.deliverableItemDateForm.controls['is_tentative_' + deliverableFound.deliverable_item_date_code.toString().replace(/-/g, '_')].setValue('all');
      // }
      return true;
    } else {
      return false;
    }
  }

  strReplace(text) {
    if (text !== null) {
      return text.toString().replace(/-/g, "_");
    }
  }

  onSelectDateOwner(dateOwner, args) {
    this.dateowner[this.i] = dateOwner;

    if (args && args.value) {
      this.articleDateForm.controls[dateOwner].setValue(args.value);
    }
  }

  getFilteredData() {
    this.isFilterReset = false;
    let filterValues = {
      ...this.filterForm.value,
      ...this.contactFilterForm.value,
      ...this.articleFilterForm.value,
      ...this.companyFilterForm.value,
      ...this.projectFilterForm.value,
    };
    // filterValues = {...filterValues, contact_company_type_code: this.selectedCompanyTypes};
    // this.contactFilterForm.controls.contact_company_type_code.setValue(this.selectedCompanyTypes);
    Object.keys(filterValues).forEach(
      (key) =>
        (filterValues[key] === null ||
          filterValues[key] === "" ||
          (Array.isArray(filterValues[key]) &&
            filterValues[key].length === 0)) &&
        delete filterValues[key],
    );

    if (filterValues.project_is_source_exist === true) {
      filterValues.project_is_source_exist = 1;
    } else {
      delete filterValues.project_is_source_exist;
    }

    if (filterValues.project_is_deleted_yn === true) {
      filterValues.project_is_deleted_yn = 1;
    } else {
      delete filterValues.project_is_deleted_yn;
    }

    if (filterValues.article_is_published_yn === true) {
      filterValues.article_is_published_yn = 1;
    } else {
      delete filterValues.article_is_published_yn;
    }

    // if (filterValues.company_is_published_yn === true) {
    //   filterValues.company_is_published_yn = 1;
    // } else {
    //   delete filterValues.company_is_published_yn;
    // }

    // if (filterValues.contact_is_published_yn === true) {
    //   filterValues.contact_is_published_yn = 1;
    // } else {
    //   delete filterValues.contact_is_published_yn;
    // }

    for (const filterValue in filterValues) {
      if (Array.isArray(filterValues[filterValue])) {
        if (this.isDate(filterValues[filterValue][0])) {
          filterValues[filterValue + "_start"] = moment(
            filterValues[filterValue][0],
          ).format("YYYY-MM-DD");
          filterValues[filterValue + "_end"] = moment(
            filterValues[filterValue][1],
          ).format("YYYY-MM-DD");
          delete filterValues[filterValue];
        }
      } else {
        // if (this.isDate(filterValues[filterValue]) && /^[a-zA-Z]+$/.test(filterValue)) {
        //   console.log("hre??????sdkfsdkjfhsdkjfhsdkjfh");
        //   filterValues[filterValue] = moment(filterValues[filterValue]).format('YYYY-MM-DD');
        // }
        // filterValues[filterValue] = moment(filterValues[filterValue]).format('YYYY-MM-DD');
        // console.log(moment(filterValues[filterValue]).format());
        // console.log();
        // if (moment(filterValues[filterValue], moment.ISO_8601, true).isValid()) {
        //     filterValues[filterValue] = moment(filterValues[filterValue]).format('YYYY-MM-DD');
        // }

        const val = filterValue.toString();
        if (val.includes("_from") || val.includes("_till")) {
          filterValues[filterValue] = moment(filterValues[filterValue]).format(
            "YYYY-MM-DD",
          );
        }
      }
    }

    for (const filterValuesKey in filterValues) {
      if (filterValues[filterValuesKey] instanceof Array) {
        filterValues[filterValuesKey] = filterValues[filterValuesKey].join(",");
      }
    }

    const articleDates = { ...this.articleDateForm.value };
    for (const articleDate in articleDates) {
      if (
        !articleDate.includes("date_owner") &&
        !articleDate.includes("is_completed") &&
        !articleDate.includes("is_tentative") &&
        articleDates[articleDate] !== null &&
        articleDates[articleDate] !== ""
      ) {
        articleDates[articleDate] = moment(articleDates[articleDate]).format(
          "YYYY-MM-DD",
        );
      } else if (articleDate.includes("is_completed")) {
        articleDates[articleDate] =
          articleDates[articleDate] === true ? 1 : null;
      } else if (articleDate.includes("date_owner")) {
        if (articleDates[articleDate] !== true) {
          if (articleDates[articleDate] !== null) {
            articleDates[articleDate] = articleDates[articleDate].join(",");
          }
        } else {
          delete articleDates[articleDate];
        }
      }
    }

    filterValues = { ...filterValues, ...articleDates };
    Object.keys(filterValues).forEach(
      (key) =>
        (filterValues[key] == null ||
          filterValues[key] === "" ||
          filterValues[key] === "Invalid date" ||
          filterValues[key] === false) &&
        delete filterValues[key],
    );

    this.router.navigate([], { queryParams: filterValues });
    if (_.isEmpty(filterValues)) {
      filterValues = { ...filterValues, page_size: 100, page_no: 1 };

      if (this.currentPage === "source") {
        filterValues = { ...filterValues, page_size: 50, page_no: 1 };
        this.store.dispatch(new ProjectSourceLoad(filterValues));
      } else if (this.currentPage === "contact") {
        this.store.dispatch(new ContactLoad(filterValues));
      } else if (this.currentPage === "article") {
        if (this.router.url.indexOf("/company/") > -1) {
          filterValues = {
            ...filterValues,
            article_company_code: this.selectedCode,
          };
          this.store.dispatch(new ArticleFilter(filterValues));
        } else {
          this.store.dispatch(new ArticleLoad(filterValues));
        }
      } else if (this.currentPage === "company") {
        this.store.dispatch(new CompanyLoad(filterValues));
      }
    }
    filterValues = { ...filterValues, page_size: 100, page_no: 1 };

    if (this.currentPage === "source") {
      filterValues = { ...filterValues, page_size: 50, page_no: 1 };
      this.store.dispatch(new ProjectSourceFilter(filterValues));
    } else if (this.currentPage === "contact") {
      this.store.dispatch(new ContactFilter(filterValues));
    }
  }

  clearForm() {
    this.queryParams = {};
    this.value = [];

    this.filterForm.reset();
    this.articleFilterForm.reset();
    this.projectFilterForm.reset();
    this.contactFilterForm.reset();
    this.companyFilterForm.reset();
    this.articleDateForm.reset();
    this.isFilterReset = true;

    if (this.currentPage === "contact") {
      this.store.dispatch(new ContactLoad({ page_size: 100, page_no: 1 }));
    } else if (this.currentPage === "source") {
      this.store.dispatch(new ProjectSourceLoad({ page_size: 20, page_no: 1 }));
    } else if (this.currentPage === "project") {
      this.store.dispatch(new ProjectLoad({ page_size: 100, page_no: 1 }));
    } else if (this.currentPage === "article") {
      if (this.router.url.indexOf("/company/") > -1) {
        this.store.dispatch(
          new ArticleFilter({ article_company_code: this.selectedCode }),
        );
      } else {
        this.store.dispatch(new ArticleLoad({ page_size: 100, page_no: 1 }));
      }
    } else if (this.currentPage === "company") {
      this.store.dispatch(new CompanyFilter({ page_size: 100, page_no: 1 }));
    }

    this.contactFilterForm.controls.contact_community_type_code.setValue("");
    this.articleFilterForm.controls.article_community_type_code.setValue("");
    this.companyFilterForm.controls.company_community_type_code.setValue("");
    this.companyFilterForm.controls.company_company_role_code.setValue("");
    this.projectFilterForm.controls.project_status_code.setValue("");
    this.filterForm.controls.project_source_type_code.setValue("");
    this.filterForm.controls.project_source_company_community_type_code.setValue(
      "",
    );
    this.projectFilterForm.controls.project_role_company_company_role_code.setValue(
      "",
    );
    this.projectFilterForm.controls.project_role_contact_community_type_code.setValue(
      "",
    );
    this.filterForm.controls.project_source_contact_community_type_code.setValue(
      "",
    );
    this.filterForm.controls.project_source_project_status_code.setValue("");
    this.projectFilterForm.controls.project_role_company_community_type_code.setValue(
      "",
    );
    this.projectFilterForm.controls.project_project_region_code.setValue("");

    // this.contactFilterForm.controls.contact_is_published_yn.setValue(false);
    this.articleFilterForm.controls.article_is_published_yn.setValue(false);
    // this.companyFilterForm.controls.company_is_published_yn.setValue(false);

    this.router.navigate([], { queryParams: {} });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.contactList) {
      this.contactListCopy = _.cloneDeep(this.contactList);
    }

    if (changes.isPopulateSidebar) {
      this.populateSidebar(this.queryParams);
    }

    if (changes.companyTypes) {
      this.companyTypeField = {
        dataSource: this.companyTypes,
        value: "company_type_code",
        text: "company_type_name",
        child: "children",
      };
    }

    if (changes.sourceTypes) {
      this.sourceTypeFields = {
        value: "project_source_type_code",
        text: "project_source_type_name",
        child: "children",
        dataSource: this.sourceTypes,
      };
    }

    if (changes.companyRoles) {
      this.companyRoleField = {
        dataSource: this.companyRoles,
        value: "company_role_code",
        text: "company_role_name",
        child: "children",
      };
    }

    if (changes.projectTypes) {
      this.projectTypeFields = {
        dataSource: this.projectTypes,
        value: "project_type_code",
        text: "project_type_name",
        child: "children",
      };
    }

    if (changes.projectStatus) {
      this.projectStatusFields = {
        dataSource: this.projectStatus,
        value: "status_code",
        text: "status_name",
        child: "children",
      };
    }

    if (changes.regions) {
      this.regionFields = {
        dataSource: this.regions,
        value: "region_code",
        text: "region_name",
        child: "children",
      };
    }
  }

  openSidebar(): void {
    this.queryParams = {};
    this.route.queryParams.subscribe((res) => {
      if (Object.keys(res).length !== 0) {
        this.queryParams = res;
        this.populateSidebar(this.queryParams);
      }
    });

    // if(this.selectedTab=='article-date'){
    //   console.log('yes article')
    //   this.route.queryParams.subscribe(res => {
    //     if (Object.keys(res).length !== 0) {
    //       this.queryParams = res;
    //     }
    //   });
    //   this.populateSidebar(this.queryParams);
    // }
    this.sidebarInstance.show();
  }

  closeSidebar(): void {
    this.store.dispatch(new ContactSetStatusNull());
    this.sidebarInstance.hide();
  }

  onBlur(args, form, field) {
    if (this.selectedTag.length != 0) {
      this.tagSelected = this.selectedTag[this.selectedTag.length - 1];

      if (!this.modelDataProjectTag.includes(this.tagSelected)) {
        this.projectTag.addItem({
          tag: this.tagSelected,
          tag_code: this.tagSelected,
        });
        form.controls[field].setValue(
          _.uniq(_.compact([...this.modelDataProjectTag, this.tagSelected])),
        );

        this.modelDataProjectTag = _.compact([
          ...this.modelDataProjectTag,
          this.tagSelected,
        ]);
        this.modelDataProjectTag.push(this.tagSelected);
      } else if (!this.modelDataCompanyTag.includes(this.tagSelected)) {
        this.companyTag.addItem({
          tag: this.tagSelected,
          tag_code: this.tagSelected,
        });
        form.controls[field].setValue(
          _.uniq(_.compact([...this.modelDataCompanyTag, this.tagSelected])),
        );

        this.modelDataCompanyTag = _.compact([
          ...this.modelDataCompanyTag,
          this.tagSelected,
        ]);
        this.modelDataCompanyTag.push(this.tagSelected);
      } else if (!this.modelDataContactTag.includes(this.tagSelected)) {
        this.contactTag.addItem({
          tag: this.tagSelected,
          tag_code: this.tagSelected,
        });
        form.controls[field].setValue(
          _.uniq(_.compact([...this.modelDataContactTag, this.tagSelected])),
        );

        this.modelDataContactTag = _.compact([
          ...this.modelDataContactTag,
          this.tagSelected,
        ]);
        this.modelDataContactTag.push(this.tagSelected);
      }

      this.selectedTag = [];
    }
  }

  syncModel = (event, type) => {
    if (type === "project") {
      this.modelDataProjectTag = event;
    } else if (type === "company") {
      this.modelDataCompanyTag = event;
    } else if (type === "contact") {
      this.modelDataContactTag = event;
    }
  };

  actionComplete(args) {
    if (
      _.get(args, "result[0]") &&
      this.tagModelData.length != _.get(args, "result", []).length
    ) {
      this.selectedTag.push(args.result[0].tag);
    }
  }

  onChange(event, type) {
    let tagsArray;
    switch (type) {
      case "project":
        tagsArray = _.compact(this.modelDataProjectTag);
        if (tagsArray.length === 0) {
          this.modelDataProjectTag = [];
        }
        break;
      case "company":
        tagsArray = _.compact(this.modelDataCompanyTag);
        if (tagsArray.length === 0) {
          this.modelDataCompanyTag = [];
        }
        break;
      case "contact":
        tagsArray = _.compact(this.modelDataContactTag);
        if (tagsArray.length === 0) {
          this.modelDataContactTag = [];
        }
        break;
    }
  }

  select(args) {
    this.selectedTag = [];
  }

  setTab(args) {
    this.selectedTab = args;

    this.queryParams = {};
    this.route.queryParams.subscribe((res) => {
      if (Object.keys(res).length !== 0) {
        this.queryParams = res;
        this.populateSidebar(this.queryParams);
      }
    });
  }

  setCompanyType(args, form) {
    this.selectedCompanyTypes = _.cloneDeep(args.value);
  }

  public onFiltering = (e, entity: string) => {
    e.preventDefaultAction = true;
    this.setDebounce(e, entity);
  };

  // public onFilter: EmitType<any> = (e: FilteringEventArgs, entity: string) => {
  //   let query = new Query();
  //   if (entity === 'company') {
  //     query = (e.text !== '') ? query.where('company_name', 'startswith', e.text, true) : query;
  //     // if (e.text && e.text !== '') {
  //     const filterParams = {company_name: e.text, page_size: 100, page_no: 1};
  //     this.companyService.getSearchedCompany(filterParams).subscribe((data) => {
  //       if (data && data.data) {
  //         if (data.data.result) {
  //           const result: any = data.data.result;
  //           e.updateData(_.cloneDeep(result), query);
  //         }
  //       }
  //     });
  //   } else if (entity === 'contact') {
  //
  //     console.log('here in filter');
  //     query = (e.text !== '') ? query.where('name', 'contains', e.text, true) : query;
  //     const contactfilterParams = {name: e.text, page_size: 40, page_no: 1};
  //     // this.store.dispatch(new GetSearchedContact(contactfilterParams));
  //
  //     // if (e.text !== '') {
  //       this.contactService.getSearchedContact(contactfilterParams).subscribe((data) => {
  //         if (data && data.data) {
  //           const companyContactList = [];
  //           if (data.data.result) {
  //             for (const contact of data.data.result) {
  //               if (contact.associated_companies) {
  //                 for (const associatedCompany of contact.associated_companies) {
  //                   let company = '';
  //                   let company_code = '';
  //                   const company_contact_code = _.clone(associatedCompany.company_contact_code);
  //
  //                   if (associatedCompany.company) {
  //                     company = ' - ' + associatedCompany.company.company_name;
  //                     company_code = associatedCompany.company.company_code;
  //                   } else {
  //                     company = ' (' + associatedCompany.email + ')';
  //                     company_code = '';
  //                   }
  //
  //                   const result = {
  //                     value: company_contact_code,
  //                     name: (contact.first_name + ' ' + contact.last_name + company),
  //                     company_code, contact_code: contact.contact_code
  //                   };
  //                   companyContactList.push(result);
  //                 }
  //               }
  //             }
  //             this.contactList = companyContactList;
  //             e.updateData(companyContactList, query);
  //           }
  //         }
  //       });
  //     // } else {
  //     //   this.contactList = this.contactListCopy;
  //     // }
  //
  //   }
  // }

  public onFilter: EmitType<any> = (e: FilteringEventArgs, entity: string) => {
    let query = new Query();

    if (entity === "company") {
      query =
        e.text !== ""
          ? query.where("company_name", "contains", e.text, true)
          : query;
      const filterParams = { company_name: e.text, page_size: 40, page_no: 1 };

      this.companyService.getSearchedCompany(filterParams).subscribe((data) => {
        if (data.data) {
          const companyList = [];
          for (const company of data.data.result) {
            companyList.push({
              company_name:
                company.company_name + " (" + company.reference_id + ")",
              company_code: company.company_code,
            });
            e.updateData(companyList, query);
          }
        }
      });

      // if (e.text && e.text !== '') {
      //   this.store.dispatch(new GetSearchedCompany(filterParams));
      // } else {
      //   const filterParams = {page_size: 40, page_no: 1};
      //   this.store.dispatch(new CompanyLoad(filterParams));
      // }

      // this.companyService.getSearchedCompany(filterParams).subscribe((data) => {
      //   if (data.data) {
      //     // this.companyList = data.data.result;
      //     e.updateData(data.data.result, query);
      //   }
      // });

      // this.store.dispatch(new CompanyFilter(filterParams));
      // // this.store.dispatch(new GetSearchedCompany({company_name: e.text}))

      // this.store.select(store => store.companies.companies).subscribe((data) => {
      //   console.log(data);
      //   if (data) {
      //     const companyList: any = data.result;
      //     // this.companyList = data.result;
      //     e.updateData(companyList, query);
      //   }
      // });
    } else if (entity === "contact") {
      // if ((this.projectCompanyRoleForm && this.projectCompanyRoleForm.controls.company_code.value !== null &&
      //   this.projectCompanyRoleForm.controls.company_code.value !== '') || this.projectSourceForm) {
      query =
        e.text !== "" ? query.where("name", "contains", e.text, true) : query;
      const contactfilterParams = {
        contact_name: e.text,
        page_size: 40,
        page_no: 1,
      };

      this.store.dispatch(new GetSearchedContact({ name: e.text }));
      this.store
        .select((store) => store.contacts.contacts)
        .subscribe((data) => {
          if (data) {
            const companyContactList = [];

            if (data.result) {
              for (const contact of data.result) {
                if (contact.associated_companies) {
                  for (const associatedCompany of contact.associated_companies) {
                    let company = "";
                    let company_code = "";
                    const company_contact_code = _.clone(
                      associatedCompany.company_contact_code,
                    );

                    if (associatedCompany.company) {
                      company = " - " + associatedCompany.company.company_name;
                      company_code = associatedCompany.company.company_code;
                    } else {
                      company = " (" + associatedCompany.email + ")";
                      company_code = "";
                    }

                    const result = {
                      value: company_contact_code,
                      name:
                        contact.first_name + " " + contact.last_name + company,
                      company_code,
                      contact_code: contact.contact_code,
                    };
                    companyContactList.push(result);
                  }
                }
              }
              // this.contactList = companyContactList;
              e.updateData(companyContactList, query);
            }
          }
        });

      // this.contactService.getSearchedContact(contactfilterParams).subscribe((data) => {
      //   if (data && data.data) {
      //     const companyContactList = [];
      //     if (data.data.result) {
      //       for (const contact of data.data.result) {
      //         if (contact.associated_companies) {
      //           for (const associatedCompany of contact.associated_companies) {
      //             let company = '';
      //             let company_code = '';
      //             const company_contact_code = _.clone(associatedCompany.company_contact_code);
      //
      //             if (associatedCompany.company) {
      //               company = ' - ' + associatedCompany.company.company_name;
      //               company_code = associatedCompany.company.company_code;
      //             } else {
      //               company = ' (' + associatedCompany.email + ')';
      //               company_code = '';
      //             }
      //
      //             const result = {
      //               value: company_contact_code,
      //               name: (contact.first_name + ' ' + contact.last_name + company),
      //               company_code, contact_code: contact.contact_code
      //             };
      //             companyContactList.push(result);
      //           }
      //         }
      //       }
      //       // this.contactList = companyContactList;
      //       e.updateData(companyContactList, query);
      //     }
      //   }
      // });

      // }
    }
  };

  isDate(str) {
    const date = Date.parse(str);

    var formats = [moment.ISO_8601, "MM/DD/YYYY  HH*mm*ss"];
    return moment(str, formats, true).isValid();
    // return (typeof str === 'object' && str instanceof Date) || (typeof str === 'string' && isNaN(+str) && !isNaN(date));
  }

  checkType(): string {
    let type = "";
    if (this.router.url.indexOf("/contact") > -1) {
      type = "contact";
    } else if (this.router.url.indexOf("/sources") > -1) {
      type = "source";
    } else if (this.router.url.indexOf("/article_news") > -1) {
      type = "article";
    } else if (this.router.url.indexOf("/project") > -1) {
      type = "project";
    } else if (this.router.url.indexOf("/contact/details/") === 0) {
      type = "project";
    } else if (this.router.url.indexOf("/company") === 0) {
      type = "company";
    }
    return type;
  }

  setDropdownValue(args, form: FormGroup, formName) {
    if (form.controls[formName]) {
      form.controls[formName].setValue(args.value);
    }
  }
  populateForm(filters) {
    for (const key in filters) {
      if (filters[key]) {
        Object.keys(this.contactFilterForm.controls).forEach((controlName) => {
          if (controlName == key) {
            this.contactFilterForm.controls[key].setValue(
              _.cloneDeep(filters[key].split(",")),
            );
          }
        });
        Object.keys(this.projectFilterForm.controls).forEach((controlName) => {
          if (controlName == key) {
            this.projectFilterForm.controls[key].setValue(
              _.cloneDeep(filters[key].split(",")),
            );
          }
        });

        Object.keys(this.articleFilterForm.controls).forEach((controlName) => {
          if (controlName == key) {
            this.articleFilterForm.controls[key].setValue(
              _.cloneDeep(filters[key].split(",")),
            );
          }
        });
        Object.keys(this.companyFilterForm.controls).forEach((controlName) => {
          if (controlName == key) {
            this.companyFilterForm.controls[key].setValue(
              _.cloneDeep(filters[key].split(",")),
            );
          }
        });
        Object.keys(this.filterForm.controls).forEach((controlName) => {
          if (controlName == key) {
            this.filterForm.controls[key].setValue(
              _.cloneDeep(filters[key].split(",")),
            );
          }
        });
      }
    }
  }
  populateSidebar(filters) {
    if (this.selectedTab == "contact") {
      this.contactFilterForm?.reset();

      for (const key in filters) {
        if (filters[key]) {
          Object.keys(this.contactFilterForm.controls).forEach(
            (controlName) => {
              if (controlName == key) {
                this.contactFilterForm.controls[key].setValue(
                  _.cloneDeep(filters[key].split(",")),
                );
              }
            },
          );
        }
      }
    }
    if (this.selectedTab == "project") {
      this.projectFilterForm?.reset();

      for (const key in filters) {
        if (filters[key]) {
          Object.keys(this.projectFilterForm.controls).forEach(
            (controlName) => {
              if (controlName == key) {
                this.projectFilterForm.controls[key].setValue(
                  _.cloneDeep(filters[key].split(",")),
                );
              }
            },
          );
        }
      }
    }
    if (this.selectedTab == "article") {
      this.articleFilterForm?.reset();

      for (const key in filters) {
        if (filters[key]) {
          Object.keys(this.articleFilterForm.controls).forEach(
            (controlName) => {
              if (controlName == key) {
                this.articleFilterForm.controls[key].setValue(
                  _.cloneDeep(filters[key].split(",")),
                );
              }
            },
          );
        }
      }
    }
    if (this.selectedTab == "company") {
      this.companyFilterForm?.reset();
      for (const key in filters) {
        if (filters[key]) {
          Object.keys(this.companyFilterForm.controls).forEach(
            (controlName) => {
              if (controlName == key) {
                this.companyFilterForm.controls[key].setValue(
                  _.cloneDeep(filters[key].split(",")),
                );
              }
            },
          );
        }
      }
    }
    if (this.selectedTab == "source") {
      this.filterForm?.reset();

      for (const key in filters) {
        if (filters[key]) {
          Object.keys(this.filterForm.controls).forEach((controlName) => {
            if (controlName == key) {
              this.filterForm.controls[key].setValue(
                _.cloneDeep(filters[key].split(",")),
              );
            }
          });
        }
      }
    }

    //if (this.selectedTab == 'article-date') {
    // this.currentDateOwnerField=[]
    // this.currentDateOwnerValue=[]
    //       for (const key in filters) {

    //         if (filters[key]) {
    //           Object.keys(this.articleDateForm.controls).forEach((controlName) => {
    //             if (controlName == key) {

    //               if (key.includes('_date_owner')) {
    //                 this.setvalue(key, filters[key].split(','))
    //               }
    //               else {
    //                 this.articleDateForm.controls[key].setValue(filters[key])
    //               }

    //             }
    //           })

    //         }
    //       }
    //     }
  }

  getArticleDates() {
    this.store.dispatch(new LoadArticleDate());

    this.store
      .select((store) => store.metadata.articleDates)
      .subscribe((data) => {
        if (data && data.result) {
          this.articleDates = data.result;
          // this.deliverableItemDatesCopy = _.cloneDeep(this.deliverableItemDates);
        }
      });
  }

  contactFocus() {
    if (false) {
      this.contactService.getSearchedContact({}).subscribe((data) => {
        if (data && data.data) {
          const companyContactList = [];
          if (data.data.result) {
            for (const contact of data.data.result) {
              if (contact.associated_companies) {
                for (const associatedCompany of contact.associated_companies) {
                  let company = "";
                  let company_code = "";
                  const company_contact_code = _.clone(
                    associatedCompany.company_contact_code,
                  );

                  if (associatedCompany.company) {
                    company = " - " + associatedCompany.company.company_name;
                    company_code = associatedCompany.company.company_code;
                  } else {
                    company = " (" + associatedCompany.email + ")";
                    company_code = "";
                  }

                  const result = {
                    value: company_contact_code,
                    name:
                      contact.first_name + " " + contact.last_name + company,
                    company_code,
                    contact_code: contact.contact_code,
                  };
                  companyContactList.push(result);
                }
              }
            }
            this.contactList = _.cloneDeep(companyContactList);
            this.articleContactDropdown.dataSource = this.contactList;
            console.log(this.contactList);
          }
        }
      });
    }
  }
  // setvalue(dateowner, Dvalue) {

  //   if (Dvalue) {

  //     this.currentDateOwnerField.push(dateowner)
  //     this.currentDateOwnerValue.push(Dvalue)
  //   }
  //   else {
  //  console.log(this.currentDateOwnerField)
  //    if(this.currentDateOwnerField.includes(dateowner)){
  //     var index=this.currentDateOwnerField.indexOf(dateowner)
  //     return this.currentDateOwnerValue[index]
  //    }
  //    else{
  //     return
  //    }
  //   }
  // }

  clearAll() {
    this.clearModal.hide();
    this.clearForm();
  }
  hideClearAllModal() {
    this.clearModal.hide();
  }
  clear() {
    this.clearModal.show();
  }
}

