import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {SidebarComponent} from '@syncfusion/ej2-angular-navigations';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {Contact} from '../../../../../core/models/contact.model';
import {emailValidator, fromToDate, telephoneValidator, trimValidator} from '../../../../../app-validators';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../core';
import {debounce, EmitType} from '@syncfusion/ej2-base';
import {DropDownListComponent, FilteringEventArgs} from '@syncfusion/ej2-angular-dropdowns';
import {Query} from '@syncfusion/ej2-data';
import {CompanyAdd, CompanyLoad, GetSearchedCompany} from '../../../../../core/store/actions/company.actions';
import {
  AddAssociateCompany, ContactLoad,
  ContactSetStatusNull,
  GetSearchedContact,
  UpdateAssociateCompany,
  GetAllAssociatedCompanies
} from '../../../../../core/store/actions/contact.actions';
import {ActivatedRoute, Router} from '@angular/router';
import {ProjectGetActivity} from '../../../../../core/store/actions/project.actions';
import * as moment from 'moment';
import {Company} from '../../../../../core/models/company.model';
import {BaseComponent} from '../../../../common/base.component';
import {AppService} from '../../../../../core/services/app.service';
import * as _ from 'lodash';
import {ContactService} from '../../../../../core/store/services/contact.service';
import {CompanyService} from '../../../../../core/store/services/company.service';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
  selector: 'app-associate-company-form',
  templateUrl: './associate-company-form.component.html',
  styleUrls: ['./associate-company-form.component.scss']
})
export class AssociateCompanyFormComponent implements OnInit, OnChanges {

  @ViewChild('company') dropdownObj: DropDownListComponent;
  @ViewChild('sidebarInstance') public sidebarInstance: SidebarComponent;
  @ViewChild('clearModal') public clearModal: DialogComponent;
  @Input() companyList = [];
  @Input() primaryCompanyList = [];
  @Output() valueChange = new EventEmitter();
  public dltButtons: ButtonPropsModel[] = [
    { click: this.clearAll.bind(this), buttonModel: { content: 'Yes', isPrimary: true, } },
    { click: this.hideClearAllModal.bind(this), buttonModel: { content: 'Cancel', isPrimary: false } }];
  public formSent: boolean;
  public width = 'auto';
  public position = 'Right';
  selectedCompanyName: string;
  selectedContactCode: string;
  associateCompanyForm: FormGroup;
  disabledState = false;
  companyListClone = [];
  public companyFields: object = {value: 'company_code', text: 'company_name'};

  error$: Observable<any>;
  successContact$: Observable<boolean>;

  public _companyInput$: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null);

  @Input() set companyInput(mode: Company | null) {
    this._companyInput$.next(mode);
  }

  get companyInput(): Company | null {
    return this._companyInput$.getValue();
  }

  setDebounce = debounce((e, entity: string) => {
    this.onFilter(e, entity);
  }, 700);

  constructor(private formBuilder: FormBuilder, private store: Store<AppState>, private route: ActivatedRoute, private router: Router,
              private companyService: CompanyService) {
  }


  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.sidebarInstance.isOpen) {
      this.closeSidebar();
    }
  }

  ngOnInit(): void {
    this.generateAssociateCompanyForm();
    this.route.parent.paramMap.subscribe((data: any) => {
      this.selectedContactCode = data.params.contact_code;

    });

    this.successContact$ = this.store.pipe(select(store => store.contacts.success.isLoaded));
    this.successContact$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
        this.closeSidebar();
        this.store.dispatch(new ContactSetStatusNull());

      }
    });

    this.error$ = this.store.pipe(select(store => store.contacts.error));
    this.error$.subscribe((data: any) => {
      if (data) {
        this.formSent = false;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
  
    if (changes.companyList) {
      this.companyListClone = _.cloneDeep(this.companyList);
    }

    if (!this.associateCompanyForm) {
      this.generateAssociateCompanyForm();
    }

    if (this._companyInput$.getValue()) {

      this.disabledState = this._companyInput$.getValue().is_display_yn === 1;

      if (this._companyInput$.getValue().company) {
        this.searchAndSetCompany();
      }
      this.associateCompanyForm.setValue({
        email: this._companyInput$.getValue().email ? this._companyInput$.getValue().email : null,
        company: this._companyInput$.getValue().company ? this._companyInput$.getValue().company.company_code : null,
        designation: this._companyInput$.getValue().designation ? this._companyInput$.getValue().designation : null,
        contactNumber: this._companyInput$.getValue().tel_no ? this._companyInput$.getValue().tel_no : null,
        is_primary_yn: this._companyInput$.getValue().is_primary_yn === 1,
        start_date: this._companyInput$.getValue().start_date ? moment(this._companyInput$.getValue().start_date, 'YYYY-MM-DD').toDate() : null,
        end_date: this._companyInput$.getValue().end_date ? moment(this._companyInput$.getValue().end_date, 'YYYY-MM-DD').toDate() : null,
        is_display_yn: this._companyInput$.getValue().is_display_yn === 1,
      });
    }
    
  }

  searchAndSetCompany() {
    if (this._companyInput$.getValue().company.company_name) {
      const isCompanyExistsInList = this.companyList.filter(el =>
        el.company_name === this._companyInput$.getValue().company.company_name).length > 0;
      if (!isCompanyExistsInList) {
        let companyList = [];
        companyList.push(this._companyInput$.getValue().company);
        this.companyList.forEach(el => companyList.push(el));
        this.dropdownObj.dataSource = companyList;
        this.associateCompanyForm.controls.company.setValue(this._companyInput$.getValue().company);
      }
    }
  }

  onInputChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    let value = inputElement.value;
    const patternP = new RegExp('^\\+?[0-9]*$');    
    const pattern0 = new RegExp('[^0-9]');
    if (value.startsWith('+')) {
      value = '+' + value.slice(1).replace(pattern0, '');
    } else {
      value = value.replace(pattern0, '');
    }
    inputElement.value = value;
    this.associateCompanyForm?.controls?.contactNumber?.setValue(value);
  }

  onPaste(event: ClipboardEvent): void {
    const inputElement = event.target as HTMLInputElement;  
    event.preventDefault();  
    const pastedData = event.clipboardData?.getData('text') || '';  
    const sanitizedValue = pastedData.startsWith('+')
      ? '+' + pastedData.slice(1).replace(/[^0-9]/g, '')
      : pastedData.replace(/[^0-9]/g, '');  
    inputElement.value = sanitizedValue;
    this.associateCompanyForm?.controls?.contactNumber?.setValue(sanitizedValue);
  }  

  generateAssociateCompanyForm() {
    this.associateCompanyForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.compose([emailValidator])]),
      company: new FormControl(null,),
      designation: new FormControl('', [Validators.maxLength(100)]),
      contactNumber: new FormControl(null, [Validators.maxLength(50)]),
      is_primary_yn: new FormControl(null),
      start_date: new FormControl(null, [Validators.required]),
      end_date: new FormControl(null),
      is_display_yn: new FormControl(null),
    }, {validator: fromToDate('start_date', 'end_date')});
  }

  openSidebar(): void {
    this.sidebarInstance.show();
  }

  closeSidebar(): void {
    this.associateCompanyForm.reset();
    this.sidebarInstance.hide();
    this.associateCompanyForm.controls.is_primary_yn.setValue(false);
    this.associateCompanyForm.controls.is_display_yn.setValue(false);
    this.valueChange.emit('success');
    this.disabledState = false;
  }


  public onFiltering = (e, entity: string) => {
    e.preventDefaultAction = true;
    this.setDebounce(e, entity);
  };

  // public onFilter: EmitType<any> = (e: FilteringEventArgs, entity) => {
  //   console.log(entity);
  //   let query = new Query();
  //   // query = (e.text !== '') ? query.where('company_name', 'startswith', e.text, true) : query;
  //   // this.selectedCompanyName = e.text;
  //   // const filterParams = {company_name: e.text, page_size: 40, page_no: 1};
  //   // this.store.dispatch(new GetSearchedCompany(filterParams));
  //   // this.store.select(store => store.companies.companies).subscribe((data) => {
  //   //   if (data && data.result) {
  //   //     const companies: any = data.result;
  //   //     this.companyList = data.result;
  //   //     e.updateData(this.companyList, query);
  //   //   }
  //   // });
  //
  //
  //   query = (e.text !== '') ? query.where('company_name', 'contains', e.text, true) : query;
  //   if (e.text && e.text !== '') {
  //     const filterParams = {company_name: e.text, page_size: 40, page_no: 1};
  //     this.store.dispatch(new GetSearchedCompany(filterParams));
  //
  //     this.store.select(store => store.companies.companies).subscribe((data) => {
  //       if (data) {
  //         this.companyList = data.result;
  //         e.updateData(this.companyList, query);
  //       }
  //     });
  //   } else {
  //     this.companyList = this.companyListClone;
  //     e.updateData(this.companyList, query);
  //
  //   }
  // }

  public onFilter: EmitType<any> = (e: FilteringEventArgs, entity: string) => {
    let query = new Query();
    if (entity === 'company') {

      query = (e.text !== '') ? query.where('company_name', 'contains', e.text, true) : query;
      if (e.text && e.text !== '') {
        const filterParams = {company_name: e.text, page_size: 40, page_no: 1};
        // this.store.dispatch(new GetSearchedCompany(filterParams));

        this.companyService.getSearchedCompany(filterParams).subscribe((data) => {
          if (data && data.data) {
            if (data.data.result) {
              // const result: any = data.data.result;
              // // this.companyList = data.result;
              // e.updateData(result, query);
              // // this.dropdownObj.dataSource = result
              // console.log(this.companyList);
              const companyList = [];
              for (const company of data.data.result) {
                companyList.push({ company_name: company.company_name + ' (' + company.reference_id + ')', company_code: company.company_code })
                e.updateData(companyList, query);
              }

            }
          }
       
        });



      }
    }
  }

  // onFocus() {
  //   this.companyList = this.companyListClone;
  // }

  addCompany(form: FormGroup) {
    if (!form.invalid) {
      this.formSent = true;
      this.store.dispatch(new AddAssociateCompany({
        contact_code: this.selectedContactCode,
        company_code: this.associateCompanyForm.value.company,
        email: this.associateCompanyForm.value.email,
        designation: this.associateCompanyForm.value.designation,
        tel_no: this.associateCompanyForm.value.contactNumber,
        is_primary_yn: !this.associateCompanyForm.value.is_primary_yn || this.associateCompanyForm.value.is_primary_yn === false ? 0 : 1,
        start_date: this.associateCompanyForm.value.start_date !== null ? moment(this.associateCompanyForm.value.start_date).format('YYYY-MM-DD') : null,
        end_date: this.associateCompanyForm.value.end_date !== null ? moment(this.associateCompanyForm.value.end_date).format('YYYY-MM-DD') : null,
        is_display_yn: !this.associateCompanyForm.value.is_display_yn || this.associateCompanyForm.value.is_display_yn === false ? 0 : 1,

      }));  
    }
    
          // this.store.dispatch(new GetAllAssociatedCompanies({
          // contact_code: this.selectedContactCode,
          //  page_size: 100,
          //   page_no: 1
          // }));
  }

  updateCompany(form: FormGroup) {
    if (!form.invalid) {

      let page = '';
      if (this.isCompanyLink()) {
        page = 'company';
      } else {
        page = 'contacts';
      }

      

      this.formSent = true;
      this.store.dispatch(new UpdateAssociateCompany({
        company_contact_code: this._companyInput$.getValue().company_contact_code,
        company_code: this.associateCompanyForm.value.company ? this.associateCompanyForm.value.company : null,
        email: this.associateCompanyForm.value.email,
        designation: this.associateCompanyForm.value.designation,
        tel_no: this.associateCompanyForm.value.contactNumber,
        is_primary_yn: this.associateCompanyForm.value.is_primary_yn === false ? 0 : 1,
        start_date: this.associateCompanyForm.value.start_date !== null ? moment(this.associateCompanyForm.value.start_date).format('YYYY-MM-DD') : null,
        end_date: this.associateCompanyForm.value.end_date !== null ? moment(this.associateCompanyForm.value.end_date).format('YYYY-MM-DD') : null, page,
        is_display_yn: this.associateCompanyForm.value.is_display_yn === false ? 0 : 1,
      }));
    }
      //  this.store.dispatch(new GetAllAssociatedCompanies({
      //      contact_code: this.selectedContactCode,
      //      page_size: 100,
      //      page_no: 1
      // }));
  }

  isCompanyLink(): boolean {
    return this.router.url.indexOf('/company') > -1;
  }

  onFocus() {
    this.dropdownObj.dataSource = this.companyListClone;
  }


  clearAll(){
    this.clearModal.hide()
    this.associateCompanyForm.reset();
  
  }
  hideClearAllModal(){
    this.clearModal.hide()
  }
  clear(){
    this.clearModal.show()
  }
}
