<ejs-sidebar id="sidebar-menu" #sidebarInstance type='Over' [width]='width' [position]='position'
             style="visibility: hidden" [enableGestures]="false" [showBackdrop]=true [ngClass]="{'long-sidebar': type === 'ltd-settings'}">

  <div class="offcanvas-close mt-n1 pr-5" style="float:right; padding: 48px;">
    <a href="javascript:;" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close"
       (click)="closeSidebar()">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>

  <div class="p-15">

    <h2 *ngIf="type === 'ltd-settings'">Company - LoveThatDesign</h2>
    <h2 *ngIf="type === 'brand'">Company - Brands</h2>
    <h2 *ngIf="type === 'images'">Company - Images</h2>


    <form id="formId" [formGroup]="companyLTDForm" #formDir="ngForm" class="form-horizontal" novalidate=""
          style="margin-top: 16px;">

      <div class="form-group sf-outline-input mb-10" *ngIf="type === 'ltd-settings'">
        <ejs-textbox id="gps_address" [multiline]="true" placeholder="Address" cssClass="e-filled"
                     formControlName="gps_address" floatLabelType="Auto"
                     (blur)="companyLTDForm.controls.gps_address.markAsTouched()"
        ></ejs-textbox>
      </div>

      <div class="d-flex mb-10 justify-content-center" *ngIf="type === 'ltd-settings'">
        <button class="btn btn-secondary text-uppercase font-weight-bold align-self-center ml-2"
                style="height: max-content" (click)="getCoordinates(companyLTDForm)"> Geocode
          <span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                <span class="svg-icon svg-icon-md"
                                      [inlineSVG]="'./assets/media/svg/icons/Navigation/Arrow-down.svg'"></span>
														</span></button>
        <button class="btn btn-secondary text-uppercase font-weight-bold align-self-center ml-2"
                style="height: max-content" (click)="getAddress(companyLTDForm)"> Reverse Geocode
          <span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                <span class="svg-icon svg-icon-md"
                                      [inlineSVG]="'./assets/media/svg/icons/Navigation/Arrow-up.svg'"></span>
															</span></button>
      </div>

      <div class="form-group sf-outline-input mb-10" *ngIf="type === 'ltd-settings'">
        <div class="d-flex">


          <ejs-textbox #latitudeInput class="mr-1" id="gps_latitude" placeholder="GPS Latitude" cssClass="e-filled"
                       formControlName="gps_latitude" floatLabelType="Auto" coordinate
                       (blur)="companyLTDForm.controls.gps_latitude.markAsTouched();onLatitudeChange()"
          ></ejs-textbox>
          <ejs-textbox #longitudeInput class="ml-1" id="gps_longitude" placeholder="GPS Longitude" cssClass="e-filled"
                       formControlName="gps_longitude" floatLabelType="Auto" coordinate
                       (blur)="companyLTDForm.controls.gps_longitude.markAsTouched();onLongitudeChange()"
          ></ejs-textbox>

          <div class="ml-3 align-self-lg-center">
            <ejs-checkbox formControlName="gps_active" #wwcheckbox label="GPS Active: "
                          labelPosition="Before"></ejs-checkbox>
          </div>


        </div>

        <div *ngIf="companyLTDForm.controls.gps_latitude.errors || companyLTDForm.controls.gps_longitude.errors">
          <div *ngIf="(companyLTDForm.controls.gps_latitude.errors && companyLTDForm.controls.gps_latitude.errors)
          || (companyLTDForm.controls.gps_longitude.errors && companyLTDForm.controls.gps_longitude.errors)"
               class="e-error">
            Please enter valid coordinates
          </div>
        </div>

      </div>

      <div class="mb-4" *ngIf="type === 'ltd-settings'">
        <agm-map [latitude]="markerLat" [longitude]="markerLng" [zoom]="14">
          <agm-marker [latitude]="markerLat" [longitude]="markerLng"></agm-marker>
        </agm-map>
      </div>

      <div class="separator separator-dashed mt-8 mb-5" *ngIf="type === 'ltd-settings'"></div>

      <div class="form-group sf-outline-input mb-10" *ngIf="type === 'ltd-settings'">
        <ejs-multiselect id='tags' #companyFormAddress floatLabelType="Auto" cssClass="e-filled"
                         formControlName='company_form_address'
                         (blur)="companyLTDForm.controls.company_form_address.markAsTouched()"
                         [allowCustomValue]=true mode='Box' placeholder='Request Form Email Addresses'
                         ngModel (created)="create($event)"></ejs-multiselect>

        <div *ngIf="companyLTDForm.controls.company_form_address.errors">
          <div *ngIf="( companyLTDForm.controls.company_form_address.errors.invalidEmail)"
               class="e-error">
            Please enter a valid email
          </div>
        </div>
      </div>

      <div class="form-group sf-outline-input mb-10" *ngIf="type === 'brand'">
        <ejs-multiselect floatLabelType="Auto" cssClass="e-filled" mode='CheckBox' showClearButton="true"
                         formControlName='brands' [fields]="brandFields" [dataSource]="brandList" sortOrder="Ascending"
                         placeholder='Select brands' [allowFiltering]='true' (filtering)='onFiltering($event,"brand")'></ejs-multiselect>
      </div>


      <div class="form-group sf-outline-input mb-10" *ngIf="type === 'ltd-settings'">
        <ejs-dropdownlist [dataSource]="defaultTabList" [fields]="defaultTabFields" formControlName="defaultTab"
                          floatLabelType="Auto" cssClass="e-filled" sortOrder="Ascending"
                          placeholder='Default Tab *'>
        </ejs-dropdownlist>
        <div *ngIf="companyLTDForm.controls.defaultTab.errors">
          <div *ngIf="companyLTDForm.controls.defaultTab.errors.required && companyLTDForm.controls.defaultTab.touched"
               class="e-error">
            Default Tab is required.
          </div>
        </div>
      </div>

     
      <div class="form-group sf-outline-input mb-10" *ngIf="type === 'ltd-settings'">
        <div>
          <ejs-dropdownlist id='visibilityDropdown' #visibility formControlName="visibility" floatLabelType="Auto" cssClass="e-filled" [value]="selectedItem"
                            [dataSource]='visibilityList' [fields]='visibilityFields' placeholder='Visibility *' (created)="setDefaultValue()"
                            (change)="changePasswordValidation(companyLTDForm, $event)">
          </ejs-dropdownlist>
          <div *ngIf="companyLTDForm.controls.visibility.errors">
            <div *ngIf="companyLTDForm.controls.visibility.errors.required && companyLTDForm.controls.visibility.touched" class="e-error">
              Visibility is required.
            </div>
          </div>
        </div>
        
        <div *ngIf="displayPasswordInput" class="mt-4">
          <ejs-textbox  placeholder="Password *" cssClass="e-filled"
                      formControlName="password_protected_password" floatLabelType="Auto"
                      (blur)="companyLTDForm.controls.password_protected_password.markAsTouched()">
          </ejs-textbox>
          <div *ngIf="companyLTDForm.controls.password_protected_password.errors">
            <div *ngIf="companyLTDForm.controls.password_protected_password.errors.required && companyLTDForm.controls.password_protected_password.touched" class="e-error">
              Password is required.
            </div>
            <div *ngIf="companyLTDForm.controls.password_protected_password.errors.maxlength && companyLTDForm.controls.password_protected_password.touched"
               class="e-error">
            Password cannot be more than 255 characters
          </div>
          </div>
        </div>

      </div>

      <div *ngIf="showUploader$|async as showUploader">
        <span class="font-weight-bold">Featured Image</span>
        <app-uploader *ngIf="showUploader" id="company-featured-uploader" (valueChange)="updateReferenceID($event)"
                      [preLoadedFiles]="preLoadedFiles" [setCleared]="setCleared$|async"></app-uploader>
      </div>


      <div class="form-group sf-outline-input mb-10" *ngIf="type === 'ltd-settings'">
        <ejs-checkbox formControlName="is_premium_company" #wwcheckbox label="Premium Company: "
                      labelPosition="Before"></ejs-checkbox>
      </div>

      <div class="form-group sf-outline-input mb-10" *ngIf="type === 'ltd-settings'">
        <ejs-checkbox formControlName="hide_socials_tab" #wwcheckbox label="Hide Socials: "
                      labelPosition="Before"></ejs-checkbox>
      </div>

      <div class="my-10 pb-20">
        <button id="resetbtn"
                class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"
                type="button" (click)="clear()"
                data-ripple="true">Clear
        </button>

        <button *ngIf="type === 'ltd-settings'"
                class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
                (click)="onUpdateCompanyLTD(companyLTDForm)" [disabled]="formSent" data-ripple="true">Update Company
        </button>
        <button *ngIf="type === 'brand'"
                class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
                (click)="onUpdateCompanyLTD(companyLTDForm)" [disabled]="formSent" data-ripple="true">Update Brands
        </button>
        <button *ngIf="type=== 'images' && !showUploadingBtn"
                class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
                (click)="onUpdateCompanyLTD(companyLTDForm)" [disabled]="formSent" data-ripple="true">Update Images
        </button>
        <button *ngIf="type=== 'images' && showUploadingBtn"
        class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"
         [disabled]=true data-ripple="true">Uploading Images...
</button>
      </div>
    </form>

    <!--    <form [formGroup]="brandForm" *ngIf="type === 'brand'">-->
    <!--      <div class="form-group sf-outline-input mb-10">-->
    <!--        <ejs-multiselect floatLabelType="Auto" cssClass="e-filled" mode='CheckBox' showClearButton="true"-->
    <!--                         formControlName='brands' [fields]="brandFields" [dataSource]="brandList"-->
    <!--                         placeholder='Select brands'></ejs-multiselect>-->
    <!--      </div>-->

    <!--      <div class="my-10 pb-20">-->
    <!--        <button id="resetbtn"-->
    <!--                class="btn btn-outline-secondary text-uppercase font-weight-bold px-15 py-5 float-right btn-block mb-5"-->
    <!--                type="button" (click)="companyLTDForm.reset();"-->
    <!--                data-ripple="true">Clear-->
    <!--        </button>-->

    <!--        <button-->
    <!--          class="btn btn-primary text-uppercase font-weight-bold px-15 py-5 float-right btn-block" type="submit"-->
    <!--          (click)="onUpdateCompanyLTD(companyLTDForm)" [disabled]="formSent" data-ripple="true">Update Company-->
    <!--        </button>-->
    <!--      </div>-->
    <!--    </form>-->


  </div>

</ejs-sidebar>

<ejs-dialog #clearModal [buttons]='dltButtons' header='Are you sure?' animationSettings='none' showCloseIcon='true'
            width='25%' [visible]='false'>
  <ng-template #content>
    <p>This action will clear all form fields.</p>
  </ng-template>
</ejs-dialog>
